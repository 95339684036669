/**
 * @file About component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useContext } from 'react';
import { I18nextContext } from '../contexts/i18nextContext.js';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Copyright from 'webcore-ux/nextgen/components/Copyright/Copyright';

const useStyles = makeStyles(() => ({
    container: {
        width: 280,
        paddingTop: 30,
        paddingBottom: 16,
    },
    title: {
        paddingLeft: 24,
    },
    titleText: {
        fontWeight: 'bold',
    },
    version: {
        paddingTop: 8,
        paddingLeft: 40,
    },
    copyright: {
        paddingTop: 8,
        paddingLeft: 40,
    },
}));

/**
 * Renders a profile menu about section
 * @param {string} appName - the name of the application
 * @param {string} version - the version of the application
 * @return {JSX.Element} - the component
 * @constructor
 */
export const About = ({ appName, version }) => {
    const i18next = useContext(I18nextContext) || { t: () => {} };
    const classes = useStyles();
    return (
        <Grid container className={classes.container}>
            <Grid item lg={12} md={12} xs={12} className={classes.title} data-testid={'de-cmn-about-app-name'}>
                <Typography variant={'subtitle2'} className={classes.titleText} data-testid={'de-cmn-profile-about-title'}>
                    {i18next.t('app-common:mainMenu.aboutMenuSectionTitle') || 'About'} {appName}
                </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} className={classes.version} data-testid={'de-cmn-about-version'}>
                <Typography variant={'subtitle2'}>
                    {i18next.t('app-common:mainMenu.versionMenuItem') || 'Version'} {version}
                </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12} className={classes.copyright} data-testid={'de-cmn-about-copyright'}>
                <Copyright twoLines={true} />
            </Grid>
        </Grid>
    );
};

About.propTypes = {
    /** the name of the application **/
    appName: PropTypes.string.isRequired,
    /** open state of dialog **/
    version: PropTypes.string.isRequired,
};

export default About;
