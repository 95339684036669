/**
 * @file getMenuConfig function
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import ConfigLoader from 'webcore-common/ConfigLoader';
import Logger from 'abb-webcore-logger/Logger';
import { defaultMenuConfig } from './defaultMenuConfig';

/**
 * get the config object for the menu component
 * @param {string} host - the host url
 * @param {function} getToken - the get token function
 * @returns {object} - the grid layout config
 */
export const getMenuConfig = async ({ host, getToken }) => {
    const configId = 'lumada-global-sideMenu-config';

    const baseOptions = { urlPrefix: host || '', getToken: getToken };
    const resp = await ConfigLoader.loadConfigV1(configId, {
        ...baseOptions,
        defaultConfig: defaultMenuConfig,
    });

    if (resp.error || !resp.config) {
        Logger.error(`getMenuConfig error: ${resp.error || 'invalid config detected'}: ${configId}`);
    }

    return resp.config;
};
