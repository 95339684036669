/**
 * @module webcore-common/HandlebarsCustomHelper
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import { showDateTime, showNumber } from './LocaleUtils';

/**
 * Register toDateTime handlebars helper
 * @param {object} handlebars instance
 */
export function registerDateTime(handlebars) {
    handlebars.registerHelper('toDateTime', function (value) {
        return showDateTime('datetime', value);
    });
    
}

/**
 * Register toDate handlebars helper
 * @param {object} handlebars instance
 */
export function registerDate(handlebars) {
    handlebars.registerHelper('toDate', function (value) {
        return showDateTime('date', value);
    });
    
}

/**
 * Register toTime handlebars helper
 * @param {object} handlebars instance
 */
export function registerTime(handlebars) {
    handlebars.registerHelper('toTime', function (value) {
        return showDateTime('time', value);
    });
    
}

/**
 * Register toNumber handlebars helper
 * @param {object} handlebars instance
 */
export function registerNumber(handlebars) {
    handlebars.registerHelper('toNumber', function (value) {
        return showNumber(value);
    });
}

/**
 * Register helper to truncate a string and show ellipsis if it exceeds the max length.
 * @param {object} handlebars instance
 */
export function registerEllipsis(handlebars) {
    handlebars.registerHelper('ellipsis', (value, maxLength) => {
        if (typeof value === 'string') {
            if (value.length > maxLength) {
                return `${value.substr(0, maxLength)}...`;
            }
        }
    
        return value;
    });
}

/**
 * Register helper to join a list of items with the specified separator
 * @param {object} handlebars instance
 */
export function registerJoin(handlebars) {
    handlebars.registerHelper('join', (separator, ...args) => {
        // Handlebars will add options object as the last parameter; remove it from items.
        const items = args.slice(0, -1);
        return items.join(separator);
    });
}

/**
 * Register helper to convert a string to base-64
 * @param {object} handlebars instance
 */
export function registerBtoa(handlebars) {
    handlebars.registerHelper('btoa', (str) => {
        // Prevent Handlebars from HTML-escaping return value
        return new handlebars.SafeString(btoa(str));
    });
}

/**
 * Register helper to generate enum label key
 * @param {object} handlebars instance
 * @param {function} getStringResource - getStringResource(key,defaulthValue)
 */
export function registerEnum2Label(handlebars, getStringResource) {
    handlebars.registerHelper("enum2Label", (enumLabelKey, enumValue) => {
        if (enumValue) {
            return getStringResource(enumLabelKey + "." + enumValue, enumValue);
        } else {
            return "";
        }
    });
}

/**
 * Register all custom helpers
 * @param {object} handlebars instance
 * @param {function} getStringResource - getStringResource(key,defaulthValue)
 */
export function registerAllHelpers(handlebars,getStringResource) {
    registerDateTime(handlebars);
    registerTime(handlebars);
    registerDate(handlebars);
    registerNumber(handlebars);
    registerEllipsis(handlebars);
    registerJoin(handlebars);
    registerBtoa(handlebars);
    registerEnum2Label(handlebars,getStringResource);
}