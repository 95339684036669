/**
 * @file WidgetAttributes
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import ModelAttributes from '../ModelAttributes/ModelAttributes';
import PropTypes from 'prop-types';

const getStringResource = (ns, options) => i18next.t(`${ns}`, options);

/**
 * WidgetAttributes component
 * @param {object} props - props for the container component
 * @param {object} props.viewSettings - settings for howto display attribues
 * @param {Promise} props.getData - promise which return the data
 * @param {object} props.loaderComponent - loading component
 * @returns {ReactElement} - WidgetAttributes component
 */
const WidgetAttributes = ({ viewSettings, getData, loaderComponent }) => {
    const [dataSource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const effects = async () => {
            setIsLoading(true);
            const data = await getData;
            setDataSource(data);
            setIsLoading(false);
        };

        effects();
    }, [getData]);

    if (!Array.isArray(viewSettings.views) || 0 === viewSettings.views.length || !viewSettings.views[0].settings) {
        return <></>;
    }

    const config = viewSettings.views[0].settings; // Assume one view for now

    if (isLoading && loaderComponent) return loaderComponent();

    return <ModelAttributes config={config.config} data={dataSource} getStringResource={getStringResource} />;
};

WidgetAttributes.propTypes = {
    /** object containing setting for the attribute component  */
    viewSettings: PropTypes.object,
    /** A function that returns a promise that will return an array of attachment data **/
    getData: PropTypes.func,
    /** An animated loading component used during fetching state **/
    loaderComponent: PropTypes.func,
};

export default WidgetAttributes;
