/**
 * @file RightContent Component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import HeaderRightActionBar from '../../HeaderRightActionBar/HeaderRightActionBar';
import ProfileMenu from '../../ProfileMenu/ProfileMenu';
import PropTypes from 'prop-types';

/**
 * Renders the MenuContent component
 * @param {string} appName - the user friendly app name
 * @param {object} settings - the application setting helper object
 * @param {object} currentUser - the current user object
 * @param {function} signOff - the sign off logic
 * @param {object} thirdPartyJson - the third party json object
 * @param {function} notificationsComponent - the notifications component
 * @param {function} helpHandler - the help handler logic
 * @returns {JSX.Element|null} - The rendered component
 * @constructor
 */
export function RightContent({ appName, settings, currentUser, signOff, thirdPartyJson, notificationsComponent, helpHandler }) {
    const ProfileMenuComponent = () => {
        return (
            <ProfileMenu
                appName={appName}
                appVersion={settings.getVersion()}
                currentUser={currentUser}
                onClickSignOff={() => signOff()}
                onClickHelp={helpHandler}
                thirdPartyJSON={thirdPartyJson}
            />
        );
    };

    return <HeaderRightActionBar notificationComponent={notificationsComponent} profileComponent={<ProfileMenuComponent />} />;
}

RightContent.defaultProps = {
    signOff: () => {},
};

RightContent.propTypes = {
    /** the user friendly app name **/
    appName: PropTypes.string.isRequired,
    /** the application settings object **/
    settings: PropTypes.shape({
        getVersion: PropTypes.func.isRequired,
    }).isRequired,
    /** the current user object **/
    currentUser: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        initials: PropTypes.string,
    }),
    /** sign off function **/
    signOff: PropTypes.func,
    /** help handler function **/
    helpHandler: PropTypes.func,
    /** the third party json object **/
    thirdPartyJson: PropTypes.object,
    /** the notifications component **/
    notificationsComponent: PropTypes.node,
};

export default RightContent;
