import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const I18nextContext = React.createContext({
    modules: [],
    t: () => {},
});
export const UpdateI18nextContext = React.createContext();

export const usei18nextContext = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useContext(I18nextContext);
};

export const useUpdatei18nextContext = () => {
    return useContext(UpdateI18nextContext);
};

const defaulti18next = {
    modules: [],
    t: () => {},
};

export const I18nextProvider = ({ value, children }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [i18next, seti18next] = useState(value || defaulti18next);

    const updateI18next = (newi18next) => {
        seti18next(newi18next);
    };

    return (
        <I18nextContext.Provider value={i18next}>
            <UpdateI18nextContext.Provider value={updateI18next}> {children}</UpdateI18nextContext.Provider>
        </I18nextContext.Provider>
    );
};

I18nextProvider.propTypes = {
    value: PropTypes.object,
    children: PropTypes.node,
};
