/**
 * @file WidgetBar component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import { WidgetHighchartsConfigFactory } from '../../../services/widget-highcharts-config-factory';
import ProgressBar from 'webcore-ux/nextgen/components/ProgressBar/ProgressBar';
import classNames from 'classnames';
import i18next from 'i18next';
import { redrawHighchartsCharts } from '../../functions/redrawHighchartCharts/redrawHighchartCharts';

/** disable highcharts.com credits on bottom of chart */
Highcharts.setOptions({
    credits: {
        enabled: false,
    },
});

/**
 * Hook used to fetch metrics for the logged in user
 * @param {function} getData - get data pending promise
 * @returns {object} -  meta and series data
 */
const useWidgetMetric = (getData) => {
    const [metric, setMetric] = useState({});

    const doFetchMetric = useCallback(async () => {
        try {
            const data = await getData();
            setMetric(data);
            return data;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(`WidgetBarChart: Failed to retrieve data: ${err}`);
        }
    }, [getData]);

    return { metric, doFetchMetric, setMetric };
};

const Container = styled.div`
    height: 95%;
    & > [data-highcharts-chart] {
        height: 100%;
        .highcharts-container {
            height: 100% !important;
            svg {
                height: 100% !important;
            }
        }
    }
`;

const Widget = styled.div`
    height: inherit;
    .hidden {
        visibility: hidden;
    }

    .visible {
        visibility: visible;
    }
`;

const defaultChartConfig = WidgetHighchartsConfigFactory({
    chart: {
        type: 'bar',
        height: 'auto',
    },
    title: '',
    series: [
        {
            data: [],
        },
    ],
});

/**
 * creates a widget component
 * @param {string} xAxis - configurations to merge into highcharts xAxis: https://api.highcharts.com/highcharts/xAxis
 * @param {string} yAxis - configurations to merge into highcharts yAxis: https://api.highcharts.com/highcharts/yAxis
 * @param {string} title - the title of the pie chart
 * @param {Array} colors - a list of hex colors to use for the chart pieces
 * @param {function} getData - a pending getData promise
 * @param {function} onClick - handler to process the pie chart section click event
 * @returns {JSX.Element} the component
 * @constructor
 */
export const WidgetBarChart = ({ yAxis = {}, xAxis = {}, title = '', colors = [], getData, onClick = () => {} }) => {
    title = i18next.t(title);

    const { metric, doFetchMetric } = useWidgetMetric(getData);
    const [chartOptions, setChartOptions] = useState(defaultChartConfig);
    const [error, setError] = useState(false);
    const containerRef = useRef({ current: {} });

    const updateChart = () =>
        WidgetHighchartsConfigFactory({
            chart: {
                type: 'bar',
                height: containerRef.current ? containerRef.current.clientHeight : '100%',
                width: containerRef.current ? containerRef.current.clientWidth : null,
            },
            title: title,
            ...(colors.length > 0 ? { colors } : {}),
            xAxis: {
                ...xAxis,
                ...metric.xAxis,
                categories: metric.categories,
            },
            yAxis: {
                ...yAxis,
                ...metric.yAxis,
            },
            series: {
                data: metric.series,
            },
            plotOptions: {
                bar: {
                    tooltip: {
                        style: { fontSize: '14px' },
                        headerFormat: '<b style="font-size:18px">{point.key}</b></br><table>',
                        pointFormat: '<span style="font-size:18px">{point.y}</span>',
                    },
                },
                series: {
                    events: {
                        click: onClick,
                    },
                },
            },
        });

    useEffect(() => {
        const effect = async () => {
            await doFetchMetric();
            if (!metric.breadcrumbId) {
                setError(true);
            }

            redrawHighchartsCharts(500);
        };

        effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const formatted = updateChart();
        setChartOptions(formatted || {});
        redrawHighchartsCharts(500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerRef.current.clientWidth, containerRef.current.clientHeight, getData, metric]);

    return (
        <Widget ref={containerRef}>
            <ProgressBar
                data-testid={'de-cmn-widget-bar-progress-bar'}
                className={classNames({ hidden: metric.breadcrumbId || error, visible: !metric.breadcrumbId && !error })}
            />
            <Container
                data-testid={'de-cmn-widget-bar-root'}
                className={classNames({ hidden: !metric.breadcrumbId || error, visible: metric.breadcrumbId })}
            >
                <HighchartsReact
                    data-testid="de-cmn-widget-bar-root"
                    highcharts={Highcharts}
                    options={chartOptions}
                    updateArgs={[true, true, true]}
                />
            </Container>
        </Widget>
    );
};

WidgetBarChart.propTypes = {
    /** x and y Axis configurations for highcharts*/
    xAxis: PropTypes.object,
    yAxis: PropTypes.object,
    /** title of the chart **/
    title: PropTypes.string,
    /** colors to be applid to the bars of data series **/
    colors: PropTypes.arrayOf(PropTypes.string),
    /** Promise that resolves chart data from API **/
    getData: PropTypes.shape({
        then: PropTypes.func.isRequired,
        catch: PropTypes.func.isRequired,
    }),
    /** ClickHandler for individual data point click - onClick=(event: MouseEvent) => {}  **/
    onClick: PropTypes.func,
};
export default WidgetBarChart;
