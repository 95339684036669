/**
 * @file container for AppWrapper
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React, { useState, useMemo } from 'react';
import ThemeProvider from 'webcore-ux/nextgen/theme/ThemeProvider';
import { I18nextProvider } from '../contexts/i18nextContext';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Logger from 'abb-webcore-logger/Logger';
import AppWrapper from './AppWrapper';
import classNames from 'classnames';
import Dialog from 'webcore-ux/nextgen/components/Dialog/Dialog';
import Branding from 'webcore-ux/nextgen/components/Branding/Branding';
import Notifications from '../Notifications/Notifications';
import LoadMask from '../LoadMask/LoadMask';
import { unsecuredApps } from '../../constants';
import { PermissionsProvider } from '../contexts/PermissionsContext';

/**
 * provides a container for AppWrapper component
 * @param {string} appName - the user friendly app name
 * @param {String} activatedApp - the activated app code string for menu
 * @param {object} settings - the settings object
 * @param {object} currentUser - the current user object
 * @param {function} getPermissionsData - the get permissions service
 * @param {function} signOff - the signoff function
 * @param {object} permissionsIn - the injected permissions object
 * @param {string[]} requiredDomains - a list of required domains for access
 * @param {string[]} requiredSubdomains - a list of required subdomains for access
 * @param {string[]} requiredActions - a list of required actions for access
 * @param {object} thirdPartyJson - the third party json object
 * @param {string} commonNamespace - the i18next common namespace
 * @param {function} getToken - the get token keycloak function
 * @param {node} headerOptionalActionsComponent - optional additional actions
 * @param {object} theme - an alternate theme for theme provider with default if not provided
 * @param {node} children - the component children
 * @return {JSX.Element} - the app wrapper component
 * @constructor
 */
export const AppWrapperContainer = ({
    appName,
    activatedApp,
    settings,
    currentUser,
    getPermissionsData,
    signOff,
    permissionsIn,
    requiredDomains,
    requiredSubdomains,
    requiredActions,
    thirdPartyJson,
    commonNamespace,
    getToken,
    headerOptionalActionsComponent,
    theme,
    children,
}) => {
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(null);
    const [access, setAccess] = useState(false);

    // get the permissions if not passed in (only once)
    useMemo(() => {
        /**
         * gets all authorized domains from required domains
         * @param {string[]} domains - array of domain objects
         * @returns {string[]} array of filtered authorized domains
         */
        const getAuthorizedDomains = (domains) => {
            return requiredDomains.filter((requiredDomain) => domains.filter((domain) => requiredDomain === domain['name']).length);
        };

        /**
         * gets all authorized subdomains from required subdomains
         * @param {string[]} domains - array of domain objects
         * @returns {string[]} array of filtered authorized subdomains
         */
        const getAuthorizedSubdomains = (domains) => {
            return requiredSubdomains.filter((requiredSubdomain) => {
                let d,
                    sd,
                    [rd, rsd] = requiredSubdomain.split('-');

                d = domains.find((domain) => domain['name'] === rd);
                if (d && d['subdomains']) {
                    sd = d['subdomains'].find((subdomain) => subdomain['name'] === rsd);
                }

                return !!sd;
            });
        };

        /**
         * gets all authorized actions from required actions
         * @param {string[]} domains - array of domain objects
         * @returns {string[]} array of filtered authorized actions
         */
        const getAuthorizedActions = (domains) => {
            return requiredActions.filter((requiredAction) => {
                let d,
                    sd,
                    a,
                    [rd, rsd, ra] = requiredAction.split('-');

                d = domains.find((domain) => domain['name'] === rd);
                if (d && d['subdomains']) {
                    sd = d['subdomains'].find((subdomain) => subdomain['name'] === rsd);
                }

                if (sd && sd['actions']) {
                    a = sd['actions'].find((action) => action['name'] === ra);
                }

                return !!a;
            });
        };

        /**
         * returns the list of authorized application
         * @param {object[]} applications - the array of applications from permissions object
         * @returns {object[]} array of filtered authorized applications
         */
        const getAuthorizedApp = (applications) => {
            // allow access if app in not secured list
            if (unsecuredApps.indexOf(activatedApp) !== -1) {
                return [{ code: activatedApp }];
            }

            return applications.filter((application) => activatedApp === application['code']);
        };

        /**
         * sets up application permissions logic
         * @param {object} permissions - the permissions object
         */
        const setAppAccess = (permissions) => {
            if (!getAuthorizedApp(permissions.applications).length) {
                Logger.warn(`User is missing the required permission '${activatedApp}' to access this app.`);
                return;
            }

            if (!requiredDomains && !requiredSubdomains && !requiredActions) {
                Logger.warn(`Application has no domain, subdomain, or action level security applied.`);
                setAccess(true);
                return;
            }

            if (requiredDomains && requiredDomains.length !== getAuthorizedDomains(permissions.domains).length) {
                Logger.warn(`User is missing the required domains to access this app.`);
                return;
            }

            if (requiredSubdomains && requiredSubdomains.length !== getAuthorizedSubdomains(permissions.domains).length) {
                Logger.warn(`User is missing the required subdomains to access this app.`);
                return;
            }

            if (requiredActions && requiredActions.length !== getAuthorizedActions(permissions.domains).length) {
                Logger.warn(`User is missing the required actions to access this app.`);
                return;
            }

            setAccess(true);
        };

        // only load permissions once
        if (!permissions) {
            if (permissionsIn) {
                setPermissions(permissionsIn);
                setAppAccess(permissionsIn);
                setLoading(false);
            } else {
                try {
                    getPermissionsData()
                        .then((data) => {
                            setPermissions(data);
                            setAppAccess(data);
                            setLoading(false);
                        })
                        .catch((error) => {
                            Logger.error('AppWrapperContainer error: ', error);
                            setLoading(false);
                        });
                } catch (error) {
                    Logger.error('AppWrapperContainer error: ', error);
                    setLoading(false);
                }
            }
        }
    }, [activatedApp, requiredDomains, requiredSubdomains, requiredActions, permissions, permissionsIn, getPermissionsData]);

    const notificationOptions = useMemo(
        () => ({
            getStringResource: (ns, options) => i18next.t(`${commonNamespace}:${ns}`, options),
            getToken: getToken,
            host: settings.getEamxHost(),
            initiator: currentUser.email,
        }),
        [commonNamespace, getToken, settings, currentUser]
    );

    /**
     * returns a configured notifications component
     * @returns {JSX.Element} - the configured notification component
     * @constructor
     */
    const NotificationsInitialized = () => {
        return <Notifications {...notificationOptions} />;
    };

    // if loading load app mask
    if (loading) {
        return (
            <ThemeProvider theme={theme}>
                <I18nextProvider value={i18next}>
                    <LoadMask />
                </I18nextProvider>
            </ThemeProvider>
        );
    }

    // if there was an issue getting permissions
    if (!permissions) {
        return (
            <ThemeProvider theme={theme}>
                <I18nextProvider value={i18next}>
                    {!permissions && (
                        <div className={classNames('de-cmn-nxt-app-error-dialog')} data-testid="de-cmn-nxt-app-error-dialog">
                            <Dialog
                                open={true}
                                showCloseButton={false}
                                disablePortal={true}
                                title={
                                    <div>
                                        <Branding />
                                        <p>
                                            {appName}: {i18next.t(`${commonNamespace}:noAppAccessError.title`)}
                                        </p>
                                    </div>
                                }
                            >
                                {i18next.t(`${commonNamespace}:noAppAccessError.dialogText`)}
                            </Dialog>
                        </div>
                    )}
                </I18nextProvider>
            </ThemeProvider>
        );
    }

    // if no access to application
    if (!access) {
        return (
            <ThemeProvider theme={theme}>
                <I18nextProvider value={i18next}>
                    {!access && (
                        <div className={classNames('de-cmn-nxt-app-error-dialog')} data-testid="de-cmn-nxt-app-error-dialog">
                            <Dialog
                                open={true}
                                showCloseButton={false}
                                disablePortal={true}
                                title={
                                    <div>
                                        <Branding />
                                        <p>
                                            {appName}: {i18next.t(`${commonNamespace}:invalidPermissionsError.title`)}
                                        </p>
                                    </div>
                                }
                            >
                                {i18next.t(`${commonNamespace}:invalidPermissionsError.dialogText`)}
                            </Dialog>
                        </div>
                    )}
                </I18nextProvider>
            </ThemeProvider>
        );
    }

    // else show application
    return (
        <ThemeProvider theme={theme}>
            <I18nextProvider value={i18next}>
                <PermissionsProvider value={permissions}>
                    <AppWrapper
                        appName={appName}
                        activatedApp={activatedApp}
                        settings={settings}
                        getToken={getToken}
                        currentUser={currentUser}
                        permissions={permissions}
                        signOff={signOff}
                        thirdPartyJson={thirdPartyJson}
                        notificationsComponent={<NotificationsInitialized />}
                        headerOptionalActionsComponent={headerOptionalActionsComponent}
                    >
                        {children}
                    </AppWrapper>
                </PermissionsProvider>
            </I18nextProvider>
        </ThemeProvider>
    );
};

AppWrapperContainer.defaultProps = {
    commonNamespace: 'app-common',
};

AppWrapperContainer.propTypes = {
    /** the user friendly app name **/
    appName: PropTypes.string.isRequired,
    /** activated app **/
    activatedApp: PropTypes.string.isRequired,
    /** the application settings object **/
    settings: PropTypes.shape({
        getVersion: PropTypes.func.isRequired,
        getHelpUrl: PropTypes.func.isRequired,
        getHelpText: PropTypes.func.isRequired,
        getLandingLink: PropTypes.func.isRequired,
        getEamxHost: PropTypes.func.isRequired,
    }).isRequired,
    /** the current user object **/
    currentUser: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        initials: PropTypes.string,
    }).isRequired,
    /** the applications permissions object **/
    permissionsIn: PropTypes.shape({
        applications: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string })),
    }),
    /** the get permissions service **/
    getPermissionsData: PropTypes.func,
    /** array of required domains for access */
    requiredDomains: PropTypes.arrayOf(PropTypes.string),
    /** array of required subdomains for access with the format <domain_name>-<subdomain_name> */
    requiredSubdomains: PropTypes.arrayOf(PropTypes.string),
    /** array of required actions for access with the format <domain_name>-<subdomain_name>-<action_name> */
    requiredActions: PropTypes.arrayOf(PropTypes.string),
    /** sign off function **/
    signOff: PropTypes.func.isRequired,
    /** the third party json object **/
    thirdPartyJson: PropTypes.object.isRequired,
    /** the i18next common namespace **/
    commonNamespace: PropTypes.string,
    /** Function used for authentication. Signature: getToken(authentication) */
    getToken: PropTypes.func.isRequired,
    /** optional additional header action icons **/
    headerOptionalActionsComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    /** overridden properties for the theme **/
    theme: PropTypes.object,
    /** the child components **/
    children: PropTypes.node,
};

export default AppWrapperContainer;
