/**
 * @file Custom Chip component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Chip } from 'webcore-ux/nextgen/components';
import { useStyles } from './styles';

/**
 * renders a custom chip component
 * @param {string} valueKey - the key of the custom value to display
 * @param {object[]} customValues - array of all possible values
 * @param {string} postfix - the text to display after the label that will not get truncated
 * @param {'left' | 'right'} avatarPosition - the position the avatar relative to the text
 * @returns {JSX.Element} - the component
 * @constructor
 */
const CustomChip = ({ valueKey, customValues, postfix, avatarPosition }) => {
    const [valueObject, setValueObject] = useState(null);
    const classes = useStyles(valueObject ? { [valueKey]: valueObject.styles } : { [valueKey]: {} })();

    useEffect(() => {
        const result = customValues.filter((item) => item.key === valueKey);
        if (result[0]) {
            setValueObject(result[0]);
        }
    }, [valueKey, customValues]);

    if (!valueObject) {
        return null;
    }

    return (
        <span className={classNames(classes.root)} data-testid="de-cmn-next-custom-chip-container">
            <Chip
                className={classNames(classes[valueKey])}
                label={valueObject.label}
                postfix={postfix}
                avatarPosition={avatarPosition}
                data-testid="de-cmn-next-custom-chip"
            />
        </span>
    );
};

CustomChip.defaultProps = {
    customValues: [],
};

CustomChip.propTypes = {
    /** key of the value to display */
    valueKey: PropTypes.string.isRequired,
    /** custom chip styles based on value **/
    customValues: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            styles: PropTypes.object,
        })
    ),
    /** Text to display after the label that will not get truncated */
    postfix: PropTypes.string,
    /** Position the avatar relative to the text */
    avatarPosition: PropTypes.oneOf(['left', 'right']),
};

export default CustomChip;
