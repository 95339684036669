/**
 * @file Redux action types and creators
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

// Action types
export const SET_CONFIG = 'SET_CONFIG';
export const CLEAR_CONFIG = 'CLEAR_CONFIG';

// Action creators
export const setConfig = (key, value) => ({ type: SET_CONFIG, key, value });
export const clearConfig = () => ({ type: CLEAR_CONFIG });
