/**
 * @file wrapper for applications with common functionality
 * @copyright © Copyright 2021 ABB. All rights reserved.
 */

import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Banner from '../Banner/Banner';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        margin: 0,
        fontFamily: theme.typography.fontFamily,
        scrollBehavior: 'smooth',
    },
    childContainer: {
        position: 'relative',
        top: theme.layout.appBar.height,
        height: `calc(100% - ${theme.layout.appBar.height}px)`, // - 56px for stripe and bar
        maxHeight: `calc(100% - ${theme.layout.appBar.height}px)`,
        paddingLeft: theme.layout.tray.closeWidth,
    },
}));

/**
 * provides a wrapper for common application functionality
 * @param {string} appName - the user friendly app name
 * @param {String} activatedApp - the activated app code string for menu
 * @param {object} settings - the settings object
 * @param {function} getToken - the keycloak get token function
 * @param {object} currentUser - the current user object
 * @param {function} signOff - the signoff function
 * @param {object} permissions - the injected permissions object
 * @param {object} thirdPartyJson - the third party json object
 * @param {function} notificationsComponent - the notifications component
 * @param {node} headerOptionalActionsComponent - optional additional actions
 * @param {function} children - the component children
 * @return {JSX.Element} - the app wrapper component
 * @constructor
 */
export const AppWrapper = ({
    appName,
    activatedApp,
    settings,
    getToken,
    currentUser,
    signOff,
    permissions,
    thirdPartyJson,
    notificationsComponent,
    headerOptionalActionsComponent,
    children,
}) => {
    const classes = useStyles();

    return (
        <div className={classNames('de-cmn-nxt-app-wrapper', classes.root)} data-testid="de-cmn-nxt-app-wrapper">
            <Banner
                appName={appName}
                activatedApp={activatedApp}
                settings={settings}
                getToken={getToken}
                currentUser={currentUser}
                signOff={() => signOff()}
                permissions={permissions}
                thirdPartyJson={thirdPartyJson}
                notificationsComponent={notificationsComponent}
                headerOptionalActionsComponent={headerOptionalActionsComponent}
            />
            <div className={classes.childContainer}>{children}</div>
        </div>
    );
};

AppWrapper.defaultProps = {
    thirdPartyJson: {},
    currentUser: {},
};

AppWrapper.propTypes = {
    /** the user friendly app name **/
    appName: PropTypes.string.isRequired,
    /** activated app **/
    activatedApp: PropTypes.string.isRequired,
    /** the application settings object **/
    settings: PropTypes.shape({
        getVersion: PropTypes.func.isRequired,
        getHelpUrl: PropTypes.func.isRequired,
        getHelpText: PropTypes.func.isRequired,
        getLandingLink: PropTypes.func.isRequired,
        getEamxHost: PropTypes.func.isRequired,
    }).isRequired,
    /** getToken function **/
    getToken: PropTypes.func.isRequired,
    /** the current user object **/
    currentUser: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        initials: PropTypes.string,
    }),
    /** the applications permissions object **/
    permissions: PropTypes.shape({
        applications: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string })),
    }),
    /** sign off function **/
    signOff: PropTypes.func.isRequired,
    /** the third party json object **/
    thirdPartyJson: PropTypes.object,
    /** the notifications component **/
    notificationsComponent: PropTypes.node,
    /** optional additional header action icons **/
    headerOptionalActionsComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    /** the child components **/
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AppWrapper;
