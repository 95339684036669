/**
 * @file useApiPreferences hook
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import { useCallback, useState } from 'react';
import Logger from 'abb-webcore-logger/Logger';
import { send, RESPONSE_TYPE } from 'webcore-common/Send';

/**
 * @typedef {object} HookOptions
 * @property {function} getToken - Function used for authentication
 * @property {string} host - Host name to send the fetch requests to
 * @property {string} id - user preference ID to fetch
 * @property {string} initiator - Email of the user whose preferences are to be fetched
 */

/**
 * Hook used to fetch user preferences for the logged in user
 * @param {object} initialValue - initial preferences (from local/session storage?)
 * @param {HookOptions} options - initial preferences (from local/session storage?)
 * @returns {HookOptions} User Preferences object
 */
const useApiPreferences = (initialValue, { getToken, host, id, initiator }) => {
    const [preferences, setPreferences] = useState(initialValue);

    const doFetchPreferences = useCallback(() => {
        const URL = `${host}/v1/configuration/preferences/user/${btoa(initiator)}/${id}`;

        return send('GET', URL, undefined, getToken, undefined, RESPONSE_TYPE.JSON)
            .then((config) => {
                setPreferences(config.configuration);

                return config.configuration;
            })
            .catch((err) => {
                Logger.error(`User configuration does not exist: ${err}`);
            });
    }, [getToken, host, id, initiator]);

    const doSavePreferences = useCallback(
        (payload) => {
            const URL = `${host}/v1/configuration/preferences/user`;

            return send('POST', URL, payload, getToken)
                .then(
                    (response) => {
                        setPreferences(payload.configuration);

                        return Promise.resolve(response);
                    },
                    (error) => {
                        return Promise.reject(error);
                    }
                )
                .catch((err) => {
                    Logger.error(`Error submitting user preferences data. Error: ${err}`);
                });
        },
        [getToken, host]
    );

    return {
        preferences,
        doFetchPreferences,
        doSavePreferences,
        setPreferences,
    };
};

export default useApiPreferences;
