/**
 * @file defaultMenuConfig object
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

export const defaultMenuConfig = {
    config: {
        global: {
            moreLinkText: 'More',
        },
        menuSections: [
            {
                menuItems: [
                    {
                        displayText: 'Home',
                        iconText: 'Home',
                        link: '/landing',
                        code: 'homeapp',
                    },
                    {
                        displayText: 'Work',
                        iconText: 'Work',
                        link: '/workorder',
                        code: 'workorderapp',
                    },
                    {
                        displayText: 'Asset Investment Planning',
                        iconText: 'AIP',
                        link: '/aip',
                        code: 'aip',
                    },
                    {
                        displayText: 'FMECA',
                        iconText: 'FMECA',
                        link: '/#/',
                        code: 'fmeca',
                        childMenuItems: [
                            {
                                displayText: 'de-deim-domain:subdomain.management',
                                link: '/reliabilitymodeler/fmeca-management',
                                subdomain: 'management',
                            },
                            {
                                displayText: 'de-deim-domain:subdomain.browser',
                                link: '/reliabilitymodeler/fmeca-browser',
                                subdomain: 'browser',
                            },
                        ],
                    },
                    {
                        displayText: 'Reliability Modeler',
                        iconText: 'RM',
                        link: '/#/',
                        code: 'reliabilitymodeler',
                        childMenuItems: [
                            {
                                displayText: 'de-deim-domain:subdomain.dashboard',
                                link: '/reliabilitymodeler/dashboard',
                                subdomain: 'rmdashboard',
                            },
                            {
                                displayText: 'de-deim-domain:subdomain.modelmanagement',
                                link: '/reliabilitymodeler/modelmanagement',
                                subdomain: 'rmmodelmanagement',
                            },
                            {
                                displayText: 'de-deim-domain:subdomain.browser',
                                link: '/reliabilitymodeler/browser',
                                subdomain: 'rmbrowser',
                            },
                        ],
                    },
                    {
                        displayText: 'List View',
                        icon: 'ListView',
                        iconText: 'List View',
                        code: 'listapp',
                        link: '/summary',
                        childMenuItems: [
                            {
                                displayText: 'Account',
                                domain: 'account',
                                childMenuItems: [
                                    {
                                        displayText: 'Account',
                                        link: '/summary/#account-account',
                                        subdomain: 'account',
                                    },
                                    {
                                        displayText: 'Cost Center',
                                        link: '/summary/#account-costcenter',
                                        subdomain: 'costcenter',
                                    },
                                    {
                                        displayText: 'GL Code',
                                        link: '/summary/#account-glcode',
                                        subdomain: 'glcode',
                                    },
                                ],
                            },
                            {
                                displayText: 'Activity',
                                subdomain: 'activity',
                                childMenuItems: [
                                    {
                                        displayText: 'Activity',
                                        link: '/summary/#activity-activity',
                                        subdomain: 'activity',
                                    },
                                ],
                            },
                            {
                                displayText: 'Appointment',
                                domain: 'account',
                                childMenuItems: [
                                    {
                                        displayText: 'Appointment',
                                        link: '/summary/#appointment-appointment',
                                        subdomain: 'account',
                                    },
                                ],
                            },
                            {
                                displayText: 'Asset',
                                domain: 'asset',
                                childMenuItems: [
                                    {
                                        displayText: 'Asset',
                                        link: '/summary/#asset-asset',
                                        subdomain: 'asset',
                                    },
                                    {
                                        displayText: 'Asset model',
                                        link: '/summary/#asset-assetmodel',
                                        subdomain: 'assetmodel',
                                    },
                                    {
                                        displayText: 'Asset position history',
                                        link: '/summary/#asset-assetpositionhistory',
                                        subdomain: 'assetpositionhistory',
                                    },
                                    {
                                        displayText: 'Asset system model position',
                                        link: '/summary/#asset-assetsystempositionmodel',
                                        subdomain: 'assetsystempositionmodel',
                                    },
                                    {
                                        displayText: 'Asset system position',
                                        link: '/summary/#asset-assetsystemposition',
                                        subdomain: 'assetsystemposition',
                                    },
                                ],
                            },
                            {
                                displayText: 'Asset Availability',
                                domain: 'assetavailability',
                                childMenuItems: [
                                    {
                                        displayText: 'Operational status tracking',
                                        link: '/summary/#assetavailability-operationalstatustracking',
                                        subdomain: 'operationalstatustracking',
                                    },
                                ],
                            },
                            {
                                displayText: 'Availability',
                                domain: 'availability',
                                childMenuItems: [
                                    {
                                        displayText: 'Absence',
                                        link: '/summary/#availability-absence',
                                        subdomain: 'absence',
                                    },
                                    {
                                        displayText: 'Availability override',
                                        link: '/summary/#availability-partyshiftoverride',
                                        subdomain: 'partyshiftoverride',
                                    },
                                    {
                                        displayText: 'Availability shift pattern',
                                        link: '/summary/#availability-partyshiftpattern',
                                        subdomain: 'partyshiftpattern',
                                    },
                                    {
                                        displayText: 'Shift',
                                        link: '/summary/#availability-shift',
                                        subdomain: 'shift',
                                    },
                                    {
                                        displayText: 'Shift pattern',
                                        link: '/summary/#availability-shiftpattern',
                                        subdomain: 'shiftpattern',
                                    },
                                    {
                                        displayText: 'Statutory holiday',
                                        link: '/summary/#availability-statutoryholiday',
                                        subdomain: 'statutoryholiday',
                                    },
                                ],
                            },
                            {
                                displayText: 'Business Process',
                                domain: 'businessprocess',
                                childMenuItems: [
                                    {
                                        displayText: 'Business process',
                                        link: '/summary/#businessprocess-businessprocess',
                                        subdomain: 'businessprocess',
                                    },
                                ],
                            },
                            {
                                displayText: 'Business Rule',
                                domain: 'businessrule',
                                childMenuItems: [
                                    {
                                        displayText: 'Business rule',
                                        link: '/summary/#businessrule-businessrule',
                                        subdomain: 'businessrule',
                                    },
                                ],
                            },
                            {
                                displayText: 'Engineering Change',
                                domain: 'engineeringchange',
                                childMenuItems: [
                                    {
                                        displayText: 'Engineering change',
                                        link: '/summary/#engineeringchange-engineeringchange',
                                        subdomain: 'engineeringchange',
                                    },
                                ],
                            },
                            {
                                displayText: 'Form',
                                domain: 'form',
                                childMenuItems: [
                                    {
                                        displayText: 'Form',
                                        link: '/summary/#form-form',
                                        subdomain: 'form',
                                    },
                                ],
                            },
                            {
                                displayText: 'Geofence',
                                domain: 'geofence',
                                childMenuItems: [
                                    {
                                        displayText: 'Geofence',
                                        link: '/summary/#geofence-geofence',
                                        subdomain: 'geofence',
                                    },
                                ],
                            },
                            {
                                displayText: 'Location',
                                domain: 'location',
                                childMenuItems: [
                                    {
                                        displayText: 'Location',
                                        link: '/summary/#location-location',
                                        subdomain: 'location',
                                    },
                                ],
                            },
                            {
                                displayText: 'Maintenance Strategy',
                                domain: 'maintenancestrategy',
                                childMenuItems: [
                                    {
                                        displayText: 'Asset measurement',
                                        link: '/summary/#maintenancestrategy-assetmeasurement',
                                        subdomain: 'assetmeasurement',
                                    },
                                    {
                                        displayText: 'Asset measurement property',
                                        link: '/summary/#maintenancestrategy-assetmeasurementproperty',
                                        subdomain: 'assetmeasurementproperty',
                                    },
                                    {
                                        displayText: 'Maintenance strategy',
                                        link: '/summary/#maintenancestrategy-maintenancestrategy',
                                        subdomain: 'maintenancestrategy',
                                    },
                                ],
                            },
                            {
                                displayText: 'People',
                                domain: 'party',
                                childMenuItems: [
                                    {
                                        displayText: 'Groups',
                                        link: '/summary/#party-group',
                                        subdomain: 'group',
                                    },
                                    {
                                        displayText: 'Individuals',
                                        link: '/summary/#party-individual',
                                        subdomain: 'individual',
                                    },
                                    {
                                        displayText: 'Organizations',
                                        link: '/summary/#party-organisation',
                                        subdomain: 'organisation',
                                    },
                                    {
                                        displayText: 'Positions',
                                        link: '/summary/#party-position',
                                        subdomain: 'position',
                                    },
                                    {
                                        displayText: 'Relationships',
                                        link: '/summary/#party-partyrelationship',
                                        subdomain: 'partyrelationship',
                                    },
                                ],
                            },
                            {
                                displayText: 'Product Service Catalog',
                                domain: 'productservicecatalogue',
                                childMenuItems: [
                                    {
                                        displayText: 'Catalog',
                                        link: '/summary/#productservicecatalogue-catalogueitem',
                                        subdomain: 'catalogueitem',
                                    },
                                    {
                                        displayText: 'Catalog item list',
                                        link: '/summary/#productservicecatalogue-catalogueitemlist',
                                        subdomain: 'catalogueitemlist',
                                    },
                                ],
                            },
                            {
                                displayText: 'Product Service Requirement',
                                domain: 'productservicerequirement',
                                childMenuItems: [
                                    {
                                        displayText: 'Product service requirement',
                                        link: '/summary/#productservicerequirement-productservicerequirement',
                                        subdomain: 'productservicerequirement',
                                    },
                                    {
                                        displayText: 'Purchase Requisition',
                                        link: '/summary/#productservicerequirement-purchaserequisition',
                                        subdomain: 'purchaserequisition',
                                    },
                                ],
                            },
                            {
                                displayText: 'Skill',
                                domain: 'skill',
                                childMenuItems: [
                                    {
                                        displayText: 'Individual skill',
                                        link: '/summary/#skill-individualskill',
                                        subdomain: 'individualskill',
                                    },
                                    {
                                        displayText: 'Skill',
                                        link: '/summary/#skill-skill',
                                        subdomain: 'skill',
                                    },
                                    {
                                        displayText: 'Skill group',
                                        link: '/summary/#skill-partyskillset',
                                        subdomain: 'partyskillset',
                                    },
                                    {
                                        displayText: 'Skillset',
                                        link: '/summary/#skill-skillset',
                                        subdomain: 'skillset',
                                    },
                                ],
                            },
                            {
                                displayText: 'Work Order',
                                domain: 'workorder',
                                childMenuItems: [
                                    {
                                        displayText: 'Work order',
                                        link: '/summary/#workorder-workorder',
                                        subdomain: 'workorder',
                                    },
                                    {
                                        displayText: 'Work order template',
                                        link: '/summary/#workorder-workordertemplate',
                                        subdomain: 'workordertemplate',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        displayText: 'Map',
                        iconText: 'Map',
                        link: '/map',
                        code: 'mapapp',
                    },
                    {
                        displayText: 'Scheduling',
                        iconText: 'Scheduling',
                        link: '/scheduling',
                        code: 'schedulingapp',
                    },
                    {
                        displayText: 'app-common:mainMenu.helpMenuItem',
                        iconText: 'app-common:mainMenu.helpMenuItem',
                        link: '[replaced by menu component]',
                        newWindow: true,
                        code: 'helpapp',
                    },
                ],
            },
            {
                sectionTitleText: 'Other Domains',
                menuItems: [
                    {
                        activated: false,
                        displayText: 'Requirements',
                        link: '/requirements',
                        code: 'catalogapp',
                    },
                    {
                        displayText: 'Admin',
                        link: '/Admin',
                        code: 'wfmadminapp',
                    },
                    {
                        displayText: 'Replay',
                        link: '/replay',
                        code: 'replayapp',
                    },
                    {
                        displayText: 'Analytics',
                        link: '/analytics',
                        code: 'analyticsapp',
                    },
                    {
                        displayText: 'Information Model',
                        link: '/deim-mgmt',
                        code: 'modelapp',
                    },
                ],
            },
            {
                sectionTitleText: 'Configurations',
                menuItems: [
                    {
                        displayText: 'Authorization Admin',
                        link: '/authorization-admin',
                        code: 'authzapp',
                    },
                    {
                        displayText: 'Languages',
                        link: '/language',
                        code: 'languageapp',
                    },
                ],
            },
        ],
    },
};
