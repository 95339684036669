// notifications session/local storage key
export const NOTIFICATIONS = {
    STORAGE_KEY: 'notifications',
    PREFERENCES_ID: 'notificationView',
};

export const NOTIFICATION_TYPES = {
    ERROR: 'Error',
    SUCCESS: 'Success',
};
