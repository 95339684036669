/**
 * @file Remote Logger Initializer - Initialize remote logging by sending configuration
 * @copyright © Copyright 2019 ABB. All rights reserved.
 * @module RemoteLoggerInitializer
 */

import Logger from "abb-webcore-logger/Logger";
import RemoteLoggerPlugin from "abb-webcore-logger/RemoteLoggerPlugin";
import FeatureFlags from "./FeatureFlags";

const REMOTE_LOGGER_ID = 'RemoteLogger';



/**
 * Indicates if the Feature Flag for the remote logger is enabled or not.
 *
 * The feature flag we are looking for is "useRemoteLogger"
 *
 * @returns {boolean} - indicates if the remote logger should be enabled
 */
export function isRemoteLoggerFeatureEnabled() {
    return (FeatureFlags.getLoaded() && FeatureFlags.enabled('useRemoteLogger'));
}

/**
 * Initialize remote logger with endpoint url and other log configurations
 * @param {object} configuration - configuration options.
 * @param {string} configuration.url - Mandatory - a URL of the server logging API.
 * @param {string} configuration.method - a HTTP method of the server logging API.
 * @param {object} configuration.headers - http headers - this is to support custom headers passed down to the post request. For example { 'my-header': 'myHeaderValue' }.
 * @param {string} configuration.token - a token for Bearer authentication scheme (see RFC 6750), e.g. UUID or JWT. By default is ''. If you set the value to undefined, the sending of logs will be suspended until the setToken method is called, but the logs will still accumulate in the queue.
 * @param {function} configuration.onUnauthorized: failedToken =>{} - this function will be called after the sending of logs is suspended due to an authentication error. A token that has not been authenticated will be passed to the function.
 * @param {number} configuration.timeout - a timeout in milliseconds
 * @param {number} configuration.interval - a time in milliseconds between sending messages. By default is 1000 (one second).
 * @param {string} configuration.level - a plugin specific level. The plugin level does not cancel the level of the logger. The highest level will be used to send messages. If the level of the logger is warn, and the level of the plugin is error, only messages with the error level will be sent to the server. By default is trace.
 * @param {object} configuration.backoff - a function used to increase the sending interval after each failed send. The function receives the duration of the previous suspend and returns the duration of the next suspend. Instead of the function backoff can be represented by an object with 3 properties: multiplier, jitter and limit. By default, it doubles the interval (multiplier=2) and adds 10% jitter (jitter=0.1). Having reached the value of 30 seconds (limit=30000), the interval increase stops. After successful sending, the interval will be reset to the initial value.
 * @param {number} configuration.backoff.multiplier - backoff multiplier.
 * @param {number} configuration.backoff.jitter - backoff jitter.
 * @param {number} configuration.backoff.limit  - backoff limit.
 * @param {number} configuration.capacity - the size of the queue in which messages are accumulated between sending. By default is 500. Overflow will delete the oldest messages. It is forbidden to set the value to 0 - in this case the default value will be used.
 * @param {object} configuration.stacktrace - object for configuring a stacktrace with the following properties.
 * @param {string[]} configuration.stacktrace.levels[] - log levels.
 * @param {number} configuration.stacktrace.depth - number of stack trace lines. By default is 3.
 * @param {number} configuration.stacktrace.excess - number of excess lines generated by following plugins (each plugin adds one line). This number of lines will be removed from the top of the stack trace. By default is 0.
 * @param {function} configuration.timestamp - a function that returns a timestamp. By default, it returns the time in the ISO UTC format.
 * @param {function} configuration.format - this option defines the logs format.
 * @param {string} configuration.prefix - log prefix.
 * @param {boolean} configuration.interceptXHR - true will capture all XHR and forwared it to log server, false will disable this feature
 */
export function initializeRemoteLogger(configuration) {

    // Reassign the existing setRequestHeader function to a wrapper on the XMLHtttpRequest class
    XMLHttpRequest.prototype.wrappedSetRequestHeader =
        XMLHttpRequest.prototype.setRequestHeader;

    // Override the existing setRequestHeader function so that it stores the headers
    XMLHttpRequest.prototype.setRequestHeader = function (header, value) {
        // Call the wrappedSetRequestHeader function first
        // so we get exceptions if we are in an erronous state etc.
        this.wrappedSetRequestHeader(header, value);

        // Create a headers map if it does not exist
        if (!this.headers) {
            this.headers = {};
        }

        // Create a list for the header that if it does not exist
        if (!this.headers[header]) {
            this.headers[header] = [];
        }

        // Add the value to the header
        this.headers[header].push(value);
    };


    let logPrefix = {};

    // If initialize is called, flush and remove any existing loggers.
    if (Logger.doesPluginExist(REMOTE_LOGGER_ID)) {
        Logger.flush();
        Logger.removePlugin(REMOTE_LOGGER_ID);
    }

    if (!isRemoteLoggerFeatureEnabled()) {
        Logger.info("The useRemoteLogger feature flag is not enabled.  No logging will be sent to the remote logger.");
        return;
    }

    //Validate configuration to check if Log server API URL is passed
    if (!configuration.url) {
        Logger.error("Cannot read property 'url' of undefined");
        return;
    }

    if (configuration.prefix) {
        logPrefix.template = configuration.prefix;
        configuration = Object.assign({}, delete configuration.prefix, configuration);
    }

    if (configuration.interceptXHR) {
        (function (open, send) {
            let xhrOpenRequestUrl,  // captured in open override
                methodType,
                queryParam,
                breadcrumbIdentifier;

            //overrides of the XHR open and send methods are now encapsulated within a closure
            XMLHttpRequest.prototype.open = function (method, url) {
                xhrOpenRequestUrl = url;     // update request url, closure variable
                methodType = method;

                if (xhrOpenRequestUrl !== configuration.url) {
                    Logger.info(`XHR:URL::${xhrOpenRequestUrl}`);
                    this.addEventListener('load', function () {
                        if (this.readyState === this.DONE) {
                            //Remote log response
                            if (this.responseType === "text") {
                                Logger.info(`XHR:RESPONSE::${this.responseText}`);
                            }
                
                            if (this.responseType === "json") {
                                Logger.info("XHR:RESPONSE::" + JSON.stringify(this.response));
                            }
                        }
                    });
                }

                open.apply(this, arguments); // reset/reapply original open method+
            };

            XMLHttpRequest.prototype.send = function (data) {
                let self = this,
                    start,
                    oldOnReadyStateChange,
                    url = xhrOpenRequestUrl;

                queryParam = data;

                function onReadyStateChange() {
                    if (self.readyState === 4 /* complete */) {
                        let time = new Date() - start;
                        let success = false;
                        breadcrumbIdentifier = ((this.headers || {}).breadcrumbid || [])[0];

                        if (self.status >= 200 && self.status < 300) {
                            success = true;
                        }

                        Logger.trackRequest(methodType, url, self.status, time, success, breadcrumbIdentifier, queryParam);
                    }

                    if (oldOnReadyStateChange) {
                        oldOnReadyStateChange();
                    }
                }

                if (xhrOpenRequestUrl !== configuration.url) {
                    // Set xhr.noIntercept to true to disable the interceptor for a particular call 
                    if (!this.noIntercept) {
                        start = new Date();
                        if (this.addEventListener) {
                            this.addEventListener("readystatechange", onReadyStateChange, false);
                        } else {
                            oldOnReadyStateChange = this.onreadystatechange;
                            this.onreadystatechange = onReadyStateChange;
                        }
                    }

                    //Remote log request data
                    Logger.info(`XHR:REQUEST::${data}`);
                }

                send.apply(this, arguments); // reset/reapply original send method
            };

        })(XMLHttpRequest.prototype.open, XMLHttpRequest.prototype.send);
    }

    configuration = Object.assign({}, delete configuration.interceptXHR, configuration);

    let remoteLogger = new RemoteLoggerPlugin(configuration, logPrefix);
    Logger.addPlugin(REMOTE_LOGGER_ID, remoteLogger);
}

export default {
    initializeRemoteLogger,
    isRemoteLoggerFeatureEnabled
};
