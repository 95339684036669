/**
 * @file DataUtils.js Contains all the common functions related to getting, setting or validating a value by path on
 * an object
 * @module webcore-common/DataUtils
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

import Logger from 'abb-webcore-logger/Logger';
import utils from './GenericDataUtils';
import Handlebars from 'handlebars/dist/handlebars.min.js';

/**
 * Gets the value of the obj at the provided path, returns the specified default (or undefined if not specified) if
 * nothing is found or the object/path is invalid.
 *
 * @param {object} obj Object to find value in
 * @param {string} path Dot notation path to find value at (e.g. "child.leaf")
 * @param {*} [defaultValue=undefined] - Optional - the default value to return if no value is found, otherwise undefined is returned
 * @returns {*} value of the object defined at the path or undefined
 */
export function getValueFromObj(obj, path, defaultValue) {
    return utils.getValueFromObj(obj, path, defaultValue);
}

// Source type enum for string template lookups
const TEMPLATE_VALUE_SOURCES = {
    MODEL: 'model', // From a data model
    CONFIG: 'config', // From a config file
    LOCALE: 'locale', // From a language translations file
};

/**
 * @param {any} source - The invalid source type
 */
function logInvalidSource(source) {
    const validSources = Object.values(TEMPLATE_VALUE_SOURCES).map(sources => `'${sources}'`).join(', ');
    Logger.error(`Invalid source for lookup specified: '${source}'. Should be one of: [${validSources}].`);
}

/**
 * @typedef {object} templateValue
 * @param {string} [template] - Template string to be interpolated, eg. '/workbench/catalog?id={{catalogUUID}}'
 * @param {object} [data] - The configuration object that maps template keys to values
 * @param {'model' | 'config' | 'locale'} [source] - Source type
 * @param {string} [path] - Dot notation path to find value at (e.g. "child.leaf")
 * @param {object} [conditions] - Conditions to check for the model object being passed in
 */

/**
 * Parses a template config into a string to be rendered, given configurations/data models to look up.
 * `template` and `data` are mutually exclusive with `source` and `path`.
 * @param {object} options - Parameters object
 * @param {templateValue} options.value - Value configuration of a child, to be parsed into a string
 * @param {object} [options.model] - An object model to look up values for the template
 * @param {object} [options.config] - Configuration object to look up values for the template
 * @param {function} [options.getLocale] - Callback to receive a translated locale string given a path
 * @returns {string | undefined} - The string value, which could be an interpolated template string depending on configuration. Or undefined if invalid.
 */
export function parseValueTemplateConfig({ value, config, model, getLocale }) {
    const { template, data, source, path, conditions = {} } = value;

    if (template) {
        return parseStringTemplate({ template, data, model, config, conditions, getLocale });
    }

    if (valueFromObjSatisfiesConditionsArray(model, conditions.conditions, conditions.type)) {
        return parsePathSource({ source, model, path, config, getLocale });
    }
}

/**
 * Parses a string template with {{ variable }} values, given configuration objects/data models to look up.
 * See: https://pgga-es.atlassian.net/wiki/spaces/EDT/pages/1494188465/
 * @param {object} options - Parameters object
 * @param {string} options.template - Template string to be interpolated, eg. '/workbench/catalog?id={{catalogUUID}}'
 * @param {object} [options.data] - The configuration object that maps template keys to values
 * @param {object} [options.model] - An object model to look up values for the template
 * @param {object} [options.config] - Configuration object to look up values for the template
 * @param {object} [options.conditions] - Conditions to check for the model object being passed in
 * @param {function} [options.getLocale] - Callback to receive a translated locale string given a path
 * @returns {string} - The interpolated template string, eg. /workbench/catalog?id=ID42321312
 */
export function parseStringTemplate({ template, data, model, config, conditions = {}, getLocale }) {
    if (!valueFromObjSatisfiesConditionsArray(model, conditions.conditions, conditions.type)) {
        return '';
    }

    if (!data) {
        return template;
    }

    const values = Object.entries(data).reduce((acc, [key, value]) => {
        const { source, path } = value;
        acc[key] = parsePathSource({ source, model, path, config, getLocale });
        return acc;
    }, {});

    // Future consideration: If compilation becomes expensive, we may want to cache the template
    return Handlebars.compile(template)(values);
}

/**
 * @param {object} options - Parameters object
 * @param {'model' | 'config' | 'locale'} [options.value.source] - Source type
 * @param {string} [options.path] - Dot notation path to find value at (e.g. "child.leaf")
 * @param {object} [options.model] - An object model to look up values for the template
 * @param {object} [options.config] - Configuration object to look up values for the template
 * @param {function} [options.getLocale] - Callback to receive a translated locale string given a path
 * @returns {string} - The parsed value given a source and a path
 */
function parsePathSource({ source, model, path, config, getLocale }) {
    const { MODEL, CONFIG, LOCALE } = TEMPLATE_VALUE_SOURCES;

    if (source === MODEL) {
        if (model) {
            return getValueFromObj(model, path);
        } else {
            Logger.error('Config specified a model lookup, but no model was supplied');
        }
    } else if (source === CONFIG) {
        if (config) {
            return getValueFromObj(config, path);
        } else {
            Logger.error('Config specified a config lookup, but no config was supplied');
        }
    } else if (source === LOCALE) {
        if (typeof getLocale === 'function') {
            return getLocale(path);
        } else {
            Logger.error('For locale source types, a valid getLocale callback must be provided');
        }
    } else {
        logInvalidSource(source);
    }

    return '';
}

/**
 * Set a value to an object at the provided path. If a path does not exist it will be created.
 * Arrays are currently not supported
 *
 * @param {object} obj Object to set value to
 * @param {string} path Dot notation path to set the value at (e.g. "child.leaf")
 * @param {*} value The value to set
 */
export function setValueToObj(obj, path, value) {
    utils.setValueToObj(obj, path, value);
}

/**
 * @typedef {object} conditionsDef
 * @property {string} type - The type of comparison to perform, value values are: gte, eq, range, in
 * @property {string|number|object} value1 - The value to compare, to, or in case of a range, the lower value
 * @property {string|number|object} value2 - Only used for ranges at this time, the upper value.
 *
 */

/**
 * Checks whether the value specified by valPath satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value from
 * @param {string} valPath - path in object to retrieve the value from
 * @param {conditionsDef[]} conditions - Array of conditions to evaluate against, multiple conditions are treated as an OR statement
 * @param {type} type - type of condition needs to be evaluated Eg:"simpleValue" etc.
 * @returns {boolean} - true if the value satisfies the condition.  false otherwise.
 */
export function valueFromObjSatisfiesCondition(obj, valPath, conditions, type) {
    return utils.valueFromObjSatisfiesCondition(obj, valPath, conditions, type, Logger);
}

/**
 * Checks whether the values specified by value1Path and value2Path satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value keys from 
 * @param {string} value1Path - path in object to retrieve the value from
 * @param {string} value2Path - path in object to retrieve the value from
 * @param {string} operator - operation needs to be performed
 * @returns {boolean} - true if both values satisfy the operation performed.  false otherwise.
 */
export function valueFromObjSatisfiesComparisonCondition(obj, value1Path, value2Path, operator) {
    return utils.valueFromObjSatisfiesComparisonCondition(obj, value1Path, value2Path, operator, Logger);
}

/**
 * Performs an 'and/or' based on the type of conditions in the list and returns true/false indicating compliance
 *
 * @param {object} obj - Object to retrieve the value from
 * @param {conditionsArrayDef} conditions - The conditions to verify
 * @param {String} type - type of condition 'and/or'
 * @returns {boolean} - Indicates if the condition checks passed or failed
 */
export function valueFromObjSatisfiesConditionsArray(obj, conditions, type) {
    return utils.valueFromObjSatisfiesConditionsArray(obj, conditions, type, Logger); 
}

/**
 * Store the picklist data to the storage.  For now, only sessionStorage and localStorage are supported.
 * Other storage type can be expanded from this function
 * 
 * @param {object} picklists - picklists section from data section in the form configuration
 * @param {object} data - picklist data
 */
export function storePicklistData(picklists, data) {
    if (picklists) {
        let retrieval = picklists.retrieval,
            listOfPicklists = picklists.lists;

        if (retrieval && listOfPicklists) {
            retrieval.forEach((items) => {
                items.lists.forEach((list) => {
                    if (list.name in listOfPicklists) {
                        let root = list.root;
                        let storageType = listOfPicklists[list.name].store.type;

                        if (storageType === 'sessionStorage' || storageType === 'localStorage') {
                            let storageKey = listOfPicklists[list.name].store.key;
                            let picklistData = getValueFromObj(data, root);
                            window[storageType].setItem(storageKey, JSON.stringify(picklistData));
                        }
                    }
                });
            });
        } else {
            Logger.error('retrieval and lists were not defined in the data section in formConfig. ');
        }
    } else {
        Logger.error('picklists is undefined.');
    }
}

export const dataUtils = {
    getValueFromObj,
    setValueToObj,
    valueFromObjSatisfiesComparisonCondition,
    valueFromObjSatisfiesCondition,
    valueFromObjSatisfiesConditionsArray
};

