/**
 * Helper function used within Widget components to render a specific default highcharts config
 * @param {chart} object - type of chart and configs
 * @param {title} string - title of chart
 * @param {object} xAxis - An array collection of configs - https://api.highcharts.com/highcharts/xAxis
 * @param {Object} yAxis - An array collection of configs - https://api.highcharts.com/highcharts/yAxis
 * @param {Object} series - data can be an array of data points, or object collection - https://api.highcharts.com/highcharts/series.bar.data
 * @param {Array} colors - An array of hex strings representing colors
 * @param {Object} plotOptions - Where an OnClick handler can be tied to series data other configs - https://api.highcharts.com/highcharts/plotOptions.series.events
 * @returns {object} - a Card config object of {id, component} for GridCardLayout
 */

export const WidgetHighchartsConfigFactory = ({
    chart = {},
    title = '',
    xAxis = {},
    yAxis = {
        title: {
            enabled: false,
        },
    },
    series = [],
    colors = ['#ccc'],
    plotOptions = {},
}) => {
    const config = {
        chart,
        title: {
            text: title,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        colors,
        series,
        xAxis: { title: { enabled: false }, ...xAxis },
        yAxis: { ...yAxis },
        plotOptions,
    };

    return config;
};
