/**
 * @file General utility functions
 * @copyright © Copyright 2019 ABB. All rights reserved.
 * @module Utils
 */

/**
 * @typedef {string} responseType
 */
/**
 * @enum {responseType}
 */
export const RESPONSE_TYPE = {
    JSON: "json",
    TEXT: "text",
    ARRAYBUFFER: "arraybuffer",
};

/**
 * Send a request, 200 and 204 will resolve, all others will be rejects.
 * @param {string} method - request method
 * @param {string} url - request url
 * @param {string|object} body - request body
 * @param {function} getToken - Function returns a Promise which resolves the token
 * @param {number} [timeout=60000] - The timeout to use, defaults to 60000 if not specified.
 * @param {responseType} [responseType] - optional response type (default text)
 * @param {object} [headers={}] - headers property of the request.
 * @returns {Promise<string|object|undefined>} promise
 */
export function send(
    method,
    url,
    body,
    getToken,
    timeout = 60000,
    responseType = RESPONSE_TYPE.TEXT,
    headers = {}
) {
    if (getToken) {
        return getToken(30).then((token) =>
            sendRequest(method, url, body, token, timeout, responseType, headers)
        );
    } else {
        return sendRequest(
            method,
            url,
            body,
            undefined,
            timeout,
            responseType,
            headers
        );
    }
}

const getXhrResponse = (xhr) => typeof xhr.response === "string"
    ? JSON.parse(xhr.response)
    : xhr.response;

const assignBreadcrumbId = (xhr, obj) => {
    if (xhr.breadcrumbId && !obj.breadcrumbId) {
        obj.breadcrumbId = xhr.breadcrumbId;
    }
};

/**
 * Sends the request with or without the token, 200 or 204 will resolve, all others reject.
 *
 * @private
 * @param {string} method - request method
 * @param {string} url - request url
 * @param {string|object} body - request body
 * @param {string} [token] - The token to send, or no token if undefined
 * @param {number} timeout - The timeout to use, in ms.
 * @param {responseType} responseType - responseType for the request
 * @param {object} [headers={}] - headers property of the request.
 * @returns {Promise<string|object|undefined>} promise
 */
function sendRequest(method, url, body, token, timeout, responseType, headers) {
    const defaultHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
    };

    const useHeaders = Object.assign({}, defaultHeaders, headers);

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = responseType;

        xhr.open(method, url);
        xhr.timeout = timeout;

        Object.getOwnPropertyNames(useHeaders).forEach((header) => {
            xhr.setRequestHeader(header, useHeaders[header]);
        });

        if (token) {
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        }

        xhr.onerror = () => {
            if (responseType === RESPONSE_TYPE.JSON) {
                let ret = {
                    status: xhr.status,
                    statusText: xhr.statusText,
                };

                assignBreadcrumbId(xhr, ret);

                try {
                    ret.body = getXhrResponse(xhr);
                } catch (e) {
                    ret.body = {};
                }

                reject(ret);
            } else {
                reject(xhr.statusText);
            }
        };

        xhr.onload = () => {
            if (xhr.status < 200 || xhr.status >= 300) {
                xhr.onerror();
            }
            
            if (xhr.status === 204) {
                resolve(undefined);
                return;
            }

            switch (responseType) {
                case RESPONSE_TYPE.JSON: {
                    try {
                        const response = getXhrResponse(xhr);
                        assignBreadcrumbId(xhr, response);

                        resolve(response);
                    } catch (e) {
                        resolve({});
                    }

                    break;
                }

                case RESPONSE_TYPE.ARRAYBUFFER:
                    resolve(xhr.response);

                    break;
                default:
                    resolve(xhr.responseText);
            }
         
        };

        xhr.ontimeout = () => {
            if (responseType === RESPONSE_TYPE.JSON) {
                return reject({
                    status: 0, // 0 means unsent or opened
                    statusText: "Request Timed Out",
                    body: null,
                    breadcrumbId: xhr.breadcrumbId,
                });
            } else {
                return reject("Request Timed Out");
            }
        };

        if (body) {
            if (typeof body === "string") {
                xhr.send(body);
            } else if (typeof body === "object" && body.constructor === ArrayBuffer) {
                xhr.send(body);
            } else {
                xhr.send(JSON.stringify(body));
            }
        } else {
            xhr.send();
        }
    });
}

export default {
    send,
};
