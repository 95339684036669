/**
 * @file getWidgetData service
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import { RESPONSE_TYPE, send } from 'webcore-common/Send';

/**
 * get data grid data from backend
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {function} config.token - the get token promise function
 * @param {string} config.host - the endpoint base host url
 * @param {object} config.payload - the payload for request
 * @returns {object} - the data result
 */
export const getDataGridData = async (config) => {
    const { domain, subdomain, token, host, payload = {} } = config;

    const URL = `${host}/v1/cq/${domain}/${domain}-de.${domain}.${subdomain}.index-1.0.0.json`;

    return send('POST', URL, payload, token, undefined, RESPONSE_TYPE.JSON);
};

/**
 * get data grid data from backend
 * @param {object} config - the service config object
 * @param {function} config.token - the get token promise function
 * @param {string} config.host - the endpoint bas host url
 * @param {string[]} config.attachmentUUIDs - the attachment uuids to be filtered
 * @returns {object} - the data result
 */
export const getAttachmentsData = async (config) => {
    const { token, host, attachmentUUIDs } = config;

    const URL = `${host}/v1/cq/attachment/attachment`;

    return send('POST', URL, { filters: { attachmentUUID: attachmentUUIDs } }, token, undefined, RESPONSE_TYPE.JSON);
};

/**
 * get wds query data from backend
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {string} config.wdsQuery - the query to be used
 * @param {string} config.wdsActionPath - the WDS action path
 * @param {function} config.token - the get token promise function
 * @param {string} config.host - the endpoint bas host url
 * @param {string} config.wdsIndex - a query index to append to the url
 * @returns {object} - the data result
 */
export const getWDSQueryData = async (config) => {
    const { domain, subdomain, wdsQuery, wdsActionPath, token, host, wdsIndex = '' } = config;
    const index = wdsIndex ? `?deimProfile=${wdsIndex}` : '';

    const URL = `${host}/v1/wds/${domain}/${subdomain}/${wdsActionPath}${index}`;

    return send('POST', URL, wdsQuery, token, undefined, RESPONSE_TYPE.JSON);
};

export { default as getAttributesData } from '../getAttributesData';
/**
 * get count data from backend
 * @param {object} config - the service config object
 * @param {string} config.cqURL - the cq-api URL endpoint used for retrieving the count data
 * @param {string} config.cqResponsePath - the path to the attribute in the response that will contain the data used in the widget
 * @param {Object[]} config.cqQuery - the metadata used to generate a cq-api query
 * @param {function} config.token - the get token promise function
 * @param {string} config.host - the endpoint bas host url
 * @returns {object} - the data result
 */
export const getCqApiQueryData = async (config) => {
    const { cqURL, cqResponsePath, cqQuery, token, host } = config;

    const URL = `${host}/${cqURL}`;

    const payload = { filters: {}, must: [] };

    cqQuery.forEach((query) => {
        if (query.filters) {
            payload.filters = { ...payload.filters, ...query.filters };
        }

        if (query.must) {
            for (let mustQueryField in query.must) {
                const field = mustQueryField + '.keyword';
                const value = '*' + query.must[mustQueryField] + '*';
                const mustQuery = { wildcard: { [field]: { value: value } } };
                payload.must.push(mustQuery);
            }
        }
    });

    try {
        const sendResponse = await send('POST', URL, payload, token, undefined, RESPONSE_TYPE.JSON);
        if (sendResponse.error) throw new Error(sendResponse.error);

        const valueToReturn = { count: sendResponse[cqResponsePath] };
        return valueToReturn;
    } catch (error) {
        throw new Error(error);
    }
};
