/**
 * @file Custom Chip styles
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */
import { makeStyles } from '@material-ui/core';

export const useStyles = (props) => {
    const styles = {
        root: {},
        ...props,
    };

    return makeStyles(styles);
};
