/**
 * @file GenericDataUtils.js Contains all the common functions related to getting, setting or validating a value by path on
 * an object.  Code is NodeJS friendly
 * @module webcore-common/GenericDataUtils
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */

const { get } = require("lodash");

/**
 * Default logger for the class (writes to console)
 */
const defaultLogger = {
    error: (msg) => {
        // eslint-disable-next-line no-console
        console.error(msg);
    },
    warn: (msg) => {
        // eslint-disable-next-line no-console
        console.warn(msg);
    },
    info: (msg) => {
        // eslint-disable-next-line no-console
        console.info(msg);
    },
    debug: (msg) => {
        // eslint-disable-next-line no-console
        console.debug(msg);
    },
};

/**
 * Gets the name of a temporary object in the original data to be used for evaluation
 * @param {string} objectType temporary object type, like iterationObj 
 * @param {string} valueKey Dot notation path to find value at (e.g. "child.leaf")
 * @returns {string} name of the temporary object
 */
function getForEachObjectName(objectType, valueKey) {
    let valueKeyStr = '' + valueKey;
    const re = new RegExp('\\.', 'g');
    return "_DE_TMP_" + objectType + "_" + valueKeyStr.replace(re,'_');
}

/**
 * Gets the value of the obj at the provided path, returns the specified default (or undefined if not specified) if
 * nothing is found or the object/path is invalid.
 *
 * @param {object} obj Object to find value in
 * @param {string} path Dot notation path to find value at (e.g. "child.leaf")
 * @param {valueKeyPreProcessor} valueKeyPreProcessor  - information for preprocessing
 * @returns {*} value of the object defined at the path or undefined
 */
function getValueWithPreProcessor(obj, path, valueKeyPreProcessor) {
    //we only process "iterationObj" type so far
    if (Array.isArray(valueKeyPreProcessor)) {        
        for (let i = 0; i < valueKeyPreProcessor.length; i++){
            if (valueKeyPreProcessor[i].type === "iterationObj" && typeof(valueKeyPreProcessor[i].iterationPath) === "string") {
                let processedPath = getForEachObjectName(valueKeyPreProcessor[i].type, valueKeyPreProcessor[i].iterationPath) + '.' + path;
                return exports.getValueFromObj(obj, processedPath);
            }
        }        
    }

    return exports.getValueFromObj(obj, path);
}

/**
 * Gets the value of the obj at the provided path, returns the specified default (or undefined if not specified) if
 * nothing is found or the object/path is invalid.
 *
 * This implementation has been replaced with Lodash get which handles the previous implementation as well as bracket notation lookups.
 *
 * @param {object} obj Object to find value in
 * @param {string} path Dot notation path to find value at (e.g. "child.leaf")
 * @param {*} [defaultValue=undefined] - Optional - the default value to return if no value is found, otherwise undefined is returned
 * @returns {*} value of the object defined at the path or undefined
 */
function getValueFromObj(obj, path, defaultValue) {
    return get(obj, path, defaultValue);
}

/**
 * Set a value to an object at the provided path. If a path does not exist it will be created.
 * Arrays are currently not supported
 *
 * @param {object} obj Object to set value to
 * @param {string} path Dot notation path to set the value at (e.g. "child.leaf")
 * @param {*} value The value to set
 */
function setValueToObj(obj, path, value) {
    let pathArray = path.split('.'),
        name = pathArray.pop(),
        node = pathArray.reduce((acc, cur) => {
            if (acc[cur] === undefined) {
                acc[cur] = {};
            }

            return acc[cur];
        }, obj);

    node[name] = value;
}


/** 
 * @typedef {object} conditionsDef
 * @property {string} type - The type of comparison to perform, value values are: gte, eq, range, in
 * @property {string|number|object} value1 - The value to compare, to, or in case of a range, the lower value
 * 
 * Checks whether the value specified by valPath satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value from
 * @param {string} valPath - path in object to retrieve the value from
 * @param {conditionsDef[]} conditions - Array of conditions to evaluate against, multiple conditions are treated as an OR statement
 * @param {type} type - type of condition needs to be evaluated Eg:"simpleValue" etc.
 * @param {object} [logger] - logger to use.  Needs to implement interface from webcore-logger
 * @returns {boolean} - true if the value satisfies the condition.  false otherwise.
 */
function valueFromObjSatisfiesCondition(obj, valPath, conditions, type, logger = defaultLogger) {
    return valueWithPreProcessorFromObjSatisfiesCondition(obj, valPath, conditions, type, logger);
}

/**
 * Checks whether the value specified by valPath and valueKeyPreProcessor satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value from
 * @param {string} valPath - path in object to retrieve the value from
 * @param {conditionsDef[]} conditions - Array of conditions to evaluate against, multiple conditions are treated as an OR statement
 * @param {type} type - type of condition needs to be evaluated Eg:"simpleValue" etc.
 * @param {object} logger - logger to use.  Needs to implement interface from webcore-logger
 * @param {valueKeyPreProcessor} valueKeyPreProcessor - information for preprocessing value from valPath
 * @returns {boolean} - true if the value satisfies the condition.  false otherwise.
 */
function valueWithPreProcessorFromObjSatisfiesCondition(obj, valPath, conditions, type, logger, valueKeyPreProcessor) {
    let val;

    //No source object?
    if (!obj || typeof obj !== "object") return false;

    //No path or condition?
    if (typeof valPath !== "string" || !Array.isArray(conditions) || conditions.length === 0) return true;

    if (!type || type === "simpleValue") {
        val = getValueWithPreProcessor(obj, valPath, valueKeyPreProcessor);
    } else {
        logger.error(`There is a misconfiguration in a condition. Unknown condition ${type}`);
        return false;
    }

    return conditions.some((condition) => computeConditions(condition.type, val, condition.value1, logger));
}
/**
 * Checks whether the values specified by value1Path and value2Path satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value keys from 
 * @param {string} value1Path - path in object to retrieve the value from
 * @param {string} value2Path - path in object to retrieve the value from
 * @param {string} operator - operation needs to be performed
 * @param {object} [logger] - logger to use.  Needs to implement interface from webcore-logger
 * @returns {boolean} - true if both values satisfy the operation performed.  false otherwise.
 */

function valueFromObjSatisfiesComparisonCondition(obj, value1Path, value2Path, operator, logger = defaultLogger) {
    return valueWithPreProcessorFromObjSatisfiesComparisonCondition(obj, value1Path, value2Path, operator, logger);
}

/**
 * Checks whether the values specified by value1Path and value2Path satisfies the conditions
 * laid out by configuration
 * @param {object} obj - Object to retrieve the value keys from 
 * @param {string} value1Path - path in object to retrieve the value from
 * @param {string} value2Path - path in object to retrieve the value from
 * @param {string} operator - operation needs to be performed
 * @param {object} logger - logger to use.  Needs to implement interface from webcore-logger
 * @param {array} valueKey1PreProcessor - information for preprocessing value1
 * @param {array} valueKey2PreProcessor - information for preprocessing value2
 * @returns {boolean} - true if both values satisfy the operation performed.  false otherwise.
 */

function valueWithPreProcessorFromObjSatisfiesComparisonCondition(obj, value1Path, value2Path, operator, logger, valueKey1PreProcessor, valueKey2PreProcessor) {
    let value1,
        value2;

    const isOperatorSupported = operator => ['eq', 'eqIgnoreCase', 'gte', 'gt', 'lte', 'lt', 'neq'].includes(operator);

    //No source object?
    if (!obj || typeof obj !== "object") return false;

    //No path or operator?
    if (typeof value1Path !== "string" || typeof value2Path !== "string" || !operator) return true;

    value1 = getValueWithPreProcessor(obj, value1Path, valueKey1PreProcessor);
    value2 = getValueWithPreProcessor(obj, value2Path, valueKey2PreProcessor);

    if ((value1 !== undefined && value1 !== null) && (value2 !== undefined && value2 !== null) && isOperatorSupported(operator)) {
        return computeConditions(operator, value1, value2, logger);
    } else {
        logger.error(`One of the fields does not exist or the operator is not supported. ${value1Path}, ${value2Path}, ${operator}`);
    }
}

/**
 * Perform the operation between two values and return the outcome of the operation
 * @param {string} type - Type of operation needs to be perfomed ex. "lte", "eq", "gte" 
 * @param {any} value1 - First value
 * @param {any} value2 - Second value
 * @param {object} [logger] - logger to use.  Needs to implement interface from webcore-logger
 * @returns {boolean} - true if both values satisfy the operation performed.  false otherwise.
 */
function computeConditions(type, value1, value2, logger = defaultLogger) {
    let found = false,
        inStr;

    const isTrue = value => [true, 'true', 1, '1'].includes(value);
    const isEmpty = value => [null, undefined, ''].includes(value);    

    switch (type) {
        case 'isTrue':
            found = isTrue(value1);
            break;
        case 'isFalse':
            found = !isTrue(value1);
            break;                   
        case 'eqIgnoreCase':
            if (typeof value1 === "string" && typeof value2 === "string") {
                const lang = window.navigator.language;
                found = value2.toLocaleLowerCase(lang) === value1.toLocaleLowerCase(lang);
            } else {
                found = value2 === value1;
            }

            break;
        case 'eq':
            found = value2 === value1;
            break;
        case 'range':
            found = (value2 <= value1) && (value1 <= value2);
            break;
        case 'in':
            inStr = value2;
            if (typeof inStr !== "string") {
                inStr = inStr.toString();
            }

            found = inStr.includes(value1);
            break;
        case 'inArray':
            if (Array.isArray(value2)) {
                found = value2.includes(value1);
            } else {
                found = false;
            }

            break;
        case 'gte':
            found = (value1 >= value2);
            break;
        case 'gt':
            found = (value1 > value2);
            break;
        case 'lte':
            found = (value1 <= value2);
            break;
        case 'lt':
            found = (value1 < value2);
            break;
        case 'hasData':
            found = !isEmpty(value1);
            break;
        case 'isEmpty':
            found = isEmpty(value1);
            break;
        case 'arrayIsEmpty':
            found = Array.isArray(value1) && value1.length === 0;
            break;
        case 'neq':
            found = value1 !== value2;
            break;
        default:
            logger.error(`There is a misconfiguration in a condition. Unknown condition ${type}`);
            break;
    }

    return found;
}

/**
 * Evaluate which conditional logic need to be called based on condition type and evaluate the result based on that
 * 
 * @param {object} obj - Object to retrieve the value keys from 
 * @param {object} condition - condition object from conditions array that has to be evaluated
 * @param {object} [logger] - logger to use.  Needs to implement interface from webcore-logger
 * @returns {boolean} - true if the value satisfies the condition.  false otherwise.
 */
function evaluateConditionBasedOnType(obj, condition, logger = defaultLogger) {
    const { valueKey, valueKey1, valueKey2, values, operator, type } = condition;

    if (condition.type === "fieldComparison") {
        return exports.valueWithPreProcessorFromObjSatisfiesComparisonCondition(obj, valueKey1, valueKey2, operator, logger, condition.valueKey1PreProcessor, condition.valueKey2PreProcessor);
    } else {
        return exports.valueWithPreProcessorFromObjSatisfiesCondition(obj, valueKey, values, type, logger, condition.valueKeyPreProcessor);
    }
}

/**
 * Performs an 'and/or/not' based on the type of conditions in the list and returns true/false indicating compliance
 *
 * @param {object} obj - Object to retrieve the value from
 * @param {conditionsArrayDef} conditions - The conditions to verify
 * @param {('and'|'or'|'not')} type - type of condition needs to be performed
 * @param {object} [logger] - logger to use.  Needs to implement interface from webcore-logger
 * @returns {boolean} - Indicates if the condition checks passed or failed
 */
function valueFromObjSatisfiesConditionsArray(obj, conditions, type, logger = defaultLogger) {
    return checkValueFromObjSatisfiesConditionsArray(obj, conditions, type, null, logger);
}

/**
 * Performs an 'and/or/not' based on the type of conditions in the list and returns true/false indicating compliance
 *
 * @param {object} obj - Object to retrieve the value from
 * @param {conditionsArrayDef} conditions - The conditions to verify
 * @param {('and'|'or'|'not')} type - type of condition needs to be performed
 *  @param {string} valueKey - the value name for ceirtain types like forEachObject
 * @param {object} logger - logger to use.  Needs to implement interface from webcore-logger
 * @returns {boolean} - Indicates if the condition checks passed or failed
 */
function checkValueFromObjSatisfiesConditionsArray(obj, conditions, type, valueKey, logger) {
    
    if (!conditions || !Array.isArray(conditions) || conditions.length === 0) {
        return true;
    }

    const conditionPasses = condition => {
        if (Object.getOwnPropertyDescriptor(condition, "conditions")) {
            return checkValueFromObjSatisfiesConditionsArray(obj, condition.conditions, condition.type, condition.valueKey, logger);
        } else {
            return exports.evaluateConditionBasedOnType(obj, condition, logger);
        }
    };

    if (!type || type === 'and') {
        return conditions.every(conditionPasses);
    } else if (type === "or") {
        return conditions.some(conditionPasses);
    } else if (type === "not") {
        if (conditions.length !== 1) {
            logger.error(`The conditions array length must be exactly 1`);
        }

        return !conditionPasses(conditions[0]);
    } else if (type === "checkObjectReference" || type === "checkArrayReference") {
        return conditions.every(conditionPasses);
    } else if (type === "forEachObject") {  
        let valueKeyValue = getValueFromObj(obj, valueKey);
        if (valueKey && Array.isArray( valueKeyValue )) {
            let result = true;
            let iterateObjName = getForEachObjectName("iterationObj", valueKey);
            valueKeyValue.forEach( (iterateObj) => {
                obj[iterateObjName] = iterateObj;
                result = result && conditions.every(conditionPasses);
            });
            
            //remove the extra property so not affect the original object for evaluation
            delete obj[iterateObjName];
            return result;
        }                

        //if valueKeyValue is null or undefined, return true, otherwise false as it is expected to be an array
        return valueKeyValue ? false : true;
    } else {
        logger.error(`There is a misconfiguration in a condition. Unknown condition ${type}`);
    }
}

/**
 * utility function to find the referenced fields from conditions
 * @param {conditions} conditions - The conditions to search
 * @returns {array} - array of such referenced fields
 */
function findReferenceFields(conditions) {
    
    /**
     * nested function to remove duplicate reference records from an array
     * @param {array} resultArray - array to be checked for duplicate records 
     * @returns {array} - array of the referenced fields with no duplication
     */
    const removeDuplicate = (resultArray) => {
        let result = [];
        for (let i = 0; i < resultArray.length; i++){
            let existed = false;
            for (let j = 0; j < result.length; j++){
                if (resultArray[i].name === result[j].name) {
                    existed = true;
                    break;
                }
            }

            if (!existed) result.push(resultArray[i]);
        }

        return result;
    };

    /**
     * nested function to search for referenced fields
     * @param {array} conditions - array to be searched for referenced fields 
     * @returns {array} - array of the referenced fields defined in the conditions
     */
    const searchReferenceInConditions = (conditions) => {
        let result = [];
        if (!conditions || !Array.isArray(conditions) || conditions.length === 0)
            return result;
        
        conditions.forEach((condition) => {
            if (condition) {
                if (condition.conditions) {
                    result.push(...searchReferenceInConditions(condition.conditions));
                } else if (condition.condition) {
                    result.push(...searchReferenceInConditions([condition.condition]));
                }
                        
                if (condition.type === 'checkObjectReference' || condition.type === 'checkArrayReference') {
                    result.push({ name: condition.valueKey, type: condition.type, deimKey: condition.deimKey });
                }
            }
            
        });

        //remove duplicates
        return removeDuplicate(result);
    };


    return searchReferenceInConditions(conditions);
}

exports = {
    getValueFromObj,
    setValueToObj,
    valueFromObjSatisfiesComparisonCondition,
    valueWithPreProcessorFromObjSatisfiesComparisonCondition,
    valueFromObjSatisfiesCondition,
    valueWithPreProcessorFromObjSatisfiesCondition,
    valueFromObjSatisfiesConditionsArray,
    evaluateConditionBasedOnType,
    findReferenceFields,
};

module.exports = exports;