/**
 * @file Configuration Loader Module
 * @copyright © Copyright 2019 ABB. All rights reserved.
 * @module webcore-common/ConfigLoader
 */

import Dexie from 'dexie';
import Logger from "abb-webcore-logger/Logger";
import { send } from "./Send";
import { setConfig, clearConfig } from './actions/actions';

// Only 1 version of each config will be stored. id(configId) is the unique key. Use Table.put() to add/replace config data sets.
const configDb = new Dexie('Config');
configDb.version(1).stores({ Config: 'id, version, configuration' });

/**
 * Function checks active config version # against data available on client side storage (IndexedDB), then fetches config data if needed.
 *
 * @param {string} configId - Id of config to be version checked and fetched.
 * @param {object} options - Extra options used when calling the function.
 * @param {string} [options.urlPrefix] - Domain name of the config api calls.
 * @param {function} options.getToken - Function that returns a Promise which resolves the token.
 * @param {function} [options.dispatch] - Dispatch function from a Redux store to dispatch an action.
 * @param {string} [options.keyName] - Name of key to save config to in redux store.
 * @param {any} options.defaultConfig - The default config data which will be used if data fetch fails.
 * @param {function} [options.outputParser] - Function that parses the config data before returning to user and saving to redux store as data from server may need to be modified depending on use cases. It can also be used for calling any functions with config data before resolving or setting to redux store. 
 * @return {Promise} - Returns the send Promise to be used to fetch config data.
 */
export function loadConfigV1(configId, options = { urlPrefix: "", getToken: undefined, dispatch: undefined, keyName: "", defaultConfig: undefined, outputParser: undefined }) {
    let { urlPrefix, getToken, dispatch, keyName, defaultConfig, outputParser } = options;

    if (!configId) {
        throw new Error("configId must be supplied to loadConfigV1");
    }

    if (typeof getToken !== "function") {
        throw new Error("getToken must be a function and must be supplied to loadConfigV1");
    }

    if (typeof dispatch !== "undefined" && typeof dispatch !== "function") {
        throw new Error("dispatch must be a function");
    }

    if (typeof defaultConfig === "undefined") {
        throw new Error("defaultConfig must be included");
    }

    if (typeof outputParser !== "undefined" && typeof outputParser !== "function") {
        throw new Error("outputParser must be a function");
    }

    let activeVersion;
    // stored config will be set to state/returned on catch. Set defaultConfig as the default data to be set on catch.
    let storedConfig = { configuration: defaultConfig };
    // defaultConfigUpdated will be set to true if storedConfig is ever updated.
    let defaultConfigUpdated = false;

    const updateActiveVersion = (version) => {
        activeVersion = version;
    };

    const updateStoredConfig = (config) => {
        storedConfig = config;
        defaultConfigUpdated = true;
    };

    let fetchActiveVersionUrl = `${urlPrefix}/v1/configuration/ui/${configId}?activeOnly=true`;

    return configDb.Config.where({ id: configId }).toArray()
        .then(res => handleQueryConfigResponse(res, updateStoredConfig))
        .then(() => send('GET', fetchActiveVersionUrl, undefined, getToken))
        .then(res => handleFetchActiveVersionResponse(res, updateActiveVersion))
        .then(() => handleFetchConfig(storedConfig, configId, activeVersion, options))
        .then(res => parseConfig(res))
        .then(res => handleSetConfig(res, options))
        .catch((e) => {
            let errMessage = `ConfigLoader: loadConfigV1 failed with message: ${e}.`;
            
            if (defaultConfigUpdated) {
                errMessage += " Stored config has been returned.";
            } else {
                errMessage += " Default config has been returned.";
            }

            let outputConfig;
            try {
                outputConfig = JSON.parse(storedConfig.configuration);
            } catch (e) {
                outputConfig = storedConfig.configuration;
            }

            if (outputParser) {
                outputConfig = handleOutputParser(outputParser, outputConfig);
            }

            if (dispatch) {
                // dispatch action to set config data. 
                dispatch(setConfig(keyName, outputConfig));
            }

            Logger.warn(errMessage);

            return Promise.resolve(outputConfig);
        });
}

/**
 * Function that clears all the stored configurations in the db storage.
 * 
 * @param {function} [dispatch] - Optional dispatch function from a Redux store to dispatch an action.
 * 
 * @returns {Promise} - Returns the clear promise.
 */
export function clearAllStoredConfig(dispatch) {
    let clearPromise = configDb.Config.clear();

    if (dispatch) {
        // dispatch action to clear config data. 
        dispatch(clearConfig());
    }

    return clearPromise;
}

/**
 * Function to handle config query response - used in loadConfig.
 *
 * @param {object} [dbConfig] - Query response from fetching config data from indexedDB.
 * @param {function} [updateStoredConfig] - Function to update loadConfig internal activeVersion value.
 */
export function handleQueryConfigResponse([dbConfig], updateStoredConfig) {
    if (dbConfig) {
        updateStoredConfig(dbConfig);
    }
}


/**
 * Function to handle API response when fetching latest config version # - used in loadConfig.
 *
 * @param {string} versionResponse - API response from fetching latest config version # in JSON format.
 * @param {function} updateActiveVersion - Function to update loadConfig internal activeVersion value.
 */
export function handleFetchActiveVersionResponse(versionResponse, updateActiveVersion) {
    let { configurations } = JSON.parse(versionResponse);

    if (!configurations || !configurations.length) {
        // if no active versions available
        throw new Error("no active versions available");
    }

    updateActiveVersion(configurations[0].version);
}

/**
 * Function to check whether version matches the activeVersion, else should fetch config from server - used in loadConfig.
 *
 * @param {object} storedConfig - object with either defaultConfig or db config.
 * @param {string} configId - Id of config.
 * @param {string} activeVersion - Config version to fetch if query response is empty.
 * @param {object} options - Extra options to use when calling fetchConfig.
 * @param {string} [options.urlPrefix] - Domain name of the config api calls.
 * @param {function} options.getToken - Function that returns a Promise which resolves the token.
 * @return {Promise<object>} - Returns a promise with the config.
 */
export const handleFetchConfig = (storedConfig, configId, activeVersion, options) => {
    if (storedConfig && storedConfig.version === activeVersion && storedConfig.id === configId) {
        // if config version is in db, return db data. 
        return Promise.resolve(storedConfig);
    } else {
        // if config version is not in db, fetch config from server
        return fetchConfig(configId, activeVersion, options);
    }
};

/**
 * Function to parse JSON string and return parsed object - used in loadConfig.
 *
 * @param {string|object} fetchedConfig - Config that is either an object or a JSON string
 * @return {object} - Returns parsed config.
 */
export const parseConfig = (fetchedConfig) => {
    let configObj;

    if (typeof fetchedConfig === "string") {
        try {
            // parse to JSON. try catch needed to avoid error. db data is returned an object while fetchConfig returns a json
            configObj = JSON.parse(fetchedConfig);
        } catch (e) {
            return fetchedConfig;
        }
    } else if (Object.prototype.toString.call(fetchedConfig) === "[object Object]") {
        // checks to see if fetchedConfig is object.
        configObj = fetchedConfig;
    } else {
        throw new Error("fetched config is invalid");
    }

    // If we get the configurations object list back, get the first instance from the list and return that.
    if (configObj && configObj.configurations && Array.isArray(configObj.configurations)) {
        return configObj.configurations[0];
    } else {
        return configObj;
    }

};

/**
 * Function to handle setting config - used in loadConfig.
 *
 * @param {string|object} config - Parsed config.
 * @param {object} options - contains redux related options.
 * @param {object} [options.dispatch] - Dispatch function from a Redux store to dispatch an action.
 * @param {string} [options.keyName] - Name of key to save config to.
 * @param {function} [options.outputParser] - Function that parses the config data before returning to user and saving to redux store as data from server may need to be modified depending on use cases. It can also be used for calling any functions with config data before resolving or setting to redux store. 
 * @return {Promise<object>} - resolves config data.
 */
export const handleSetConfig = (config, options) => {
    return new Promise((resolve, reject) => {
        let {id, version, configuration} = config;
        let {dispatch, keyName, outputParser} = options;
        let outputConfig,
            strConfig;

        if (!id || !version || configuration === undefined) {
            reject(new Error("id, version, or configuration is missing in handleSetConfig"));
            return;
        }

        if (typeof configuration === "string") {
            strConfig = configuration;
            try {
                outputConfig = JSON.parse(configuration);
            } catch (e) {
                reject(new Error("unable be parse configuration in handleSetConfig"));
                return;
            }
        } else if (typeof configuration === "object") {
            outputConfig = configuration;
            strConfig = JSON.stringify(outputConfig, undefined, 0);
        } else {
            reject(new Error("Invalid configuration passed to handleSetConfig"));
            return;
        }

        // put is used to add/update db
        // config is stored as a string in the indexedDB
        configDb.Config.put({
            id: id,
            version: version,
            configuration: strConfig
        }).then(() => {

            // output is parsed by outputParser if it exists
            if (outputParser) {
                outputConfig = handleOutputParser(outputParser, outputConfig);
            }

            if (dispatch) {
                // dispatch action to set config data and update db if needed.
                dispatch(setConfig(keyName, outputConfig));
            }

            resolve(outputConfig);
        }).catch((e) => {
            reject(e);
        });
    });
};

/**
 * Function to handle calling outputParser.
 *
 * @param {function} outputParser - Function that parses data.
 * @param {object} data - Data to be used in outputParser.
 * @return {any} - resolves config data.
 */
export const handleOutputParser = (outputParser, data) => {
    if (typeof data !== "undefined") {
        // deep clones data in case of mutation from user. This doesn't clone all data types, but should clone all data returned by server as server also sends back JSON string.
        let parsedData = JSON.parse(JSON.stringify(data));

        if (outputParser) {
            // call outputParser, must be called before "typeof parsedData === undefined" check
            parsedData = outputParser(parsedData);

            if (typeof parsedData === "undefined") {
                // check that parsedData has a value
                throw new Error("outputParser must return a value");
            }
        }

        return parsedData;
    } else {
        throw new Error("data must be defined in handleOutputParser");
    }
};

/**
 * Function to set reducers
 *
 * @param {object} state - The stores state
 * @param {object} action - Action items to be used within the reducer
 * @returns {object} new config state
 */
export function configReducer(state = {}, action) {
    switch (action.type) {
        case "SET_CONFIG":
            return Object.assign({}, state, action.key ? { [action.key]: action.value } : action.value);
        case "CLEAR_CONFIG":
            return {};
        default:
            return state;
    }
}

/**
 * Internal helper function to fetch config
 *
 * @param {string} id - Id of config to be fetched
 * @param {string} version - Version # of config to be fetched
 * @param {object} options - Extra options used when calling the function.
 * @param {string} [options.urlPrefix] - Domain name of the config api calls.
 * @param {function} options.getToken - Function that returns a Promise which resolves the token.

 * @return {Promise<object>} - Returns the send Promise to be used to fetch config
 */
export function fetchConfig(id, version, options = { urlPrefix: "", getToken: undefined }) {
    let { urlPrefix, getToken } = options;

    if (!id || !version) {
        throw new Error("id or version not supplied to fetchConfig in ConfigLoader");
    }

    if (typeof getToken !== "function") {
        throw new Error("getToken must be a function and must be supplied to fetchConfig");
    }

    let fetchConfigUrl = `${urlPrefix}/v1/configuration/ui/${id}/${version}`;
    return send('GET', fetchConfigUrl, undefined, getToken);
}

export default {
    loadConfigV1,
    configReducer,
    clearAllStoredConfig
};