/**
 * @file AuthorizationAPI.js - API calls for retrieving user authorization which includes application and domain access information
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd All rights reserved.
 */

import { send } from 'webcore-common';

/**
 * Request to get the user authorization data
 * API will return application access and domain/subdomain information which are authorized for the user
 * @param {string} hostURL - The Host to retrieve the authorization from, the expectation is that there is no trailing / on the URL
 * @param {getTokenCallback} getToken - function that resolves to a valid token for the hostURL
 * @returns {Promise<object>} return the send promise
 */
export const getUserAuthorizationData = (hostURL, getToken) => {
    return send('GET', `${hostURL}/v1/authorization/permissions`, {}, getToken)
        .then((response) => {
            const responseJson = JSON.parse(response);
            if (responseJson.errorCode) {
                return Promise.reject(responseJson.description);
            }

            return Promise.resolve(responseJson);
        })
        .catch((error) => {
            return Promise.reject(`Error getting user authorization data. Error: ${error}`);
        });
};
