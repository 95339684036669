import { getValueFromObj } from 'webcore-common';
import Logger from 'abb-webcore-logger/Logger';
import { getCQWorkbenchData } from '../api';
/**
 * Get all the foreign reference field data depending on the view configuration.
 * @param {object} config - config for getting data
 * @param {object} config.viewSettings - view settings containing all necessary filter criteria
 * @param {Function} config.getToken - auth token promise
 * @param {string} config.domain - the default/base type of the attribute card
 * @param {string} config.subdomain - the default/base sub-type of the attribute card
 * @param {object} config.baseData - dynamic filter base data containing all reference fields
 * @returns {Array} Array of fetched data
 */
const getAttributesData = (config) => {
    const results = [];
    const { viewSettings, getToken, domain, subdomain, baseData, host } = config;
    const filterSortConfAry = buildFilterAndSortAry(viewSettings, baseData);

    if (!filterSortConfAry) return;

    // For each filterCriteria, get the corresponding CQ data
    filterSortConfAry.forEach((aFilter) => {
        const [filterConfig, sortConfig, baseType] = aFilter;
        let aDom = domain,
            aSubDom = subdomain;

        // Only change the base type if the override is properly configured
        if (baseType && baseType.domain && baseType.subdomain) {
            aDom = baseType.domain;
            aSubDom = baseType.subdomain;
        }

        results.push({
            subdomain: aSubDom,
            promise: getCQWorkbenchData(0, 10, filterConfig, sortConfig, getToken, aDom, aSubDom, host)
                .then((values) => {
                    const firstVal = values[0];
                    if ('object' === typeof firstVal && firstVal.results) {
                        const details = Array.isArray(firstVal.results) && 0 < firstVal.results.length ? firstVal.results[0] : null;
                        return details;
                    } else {
                        Logger.error('Error while retrieving CQ Workbench attribute data: ' + firstVal);
                    }
                })
                .catch((e) => Logger.error('Error while retrieving CQ Workbench attribute data: ' + e)),
        });
    });

    return Promise.all(results.map((item) => item.promise)).then((data) => {
        if (data.length === 1) {
            return data[0];
        }

        return data.reduce((acc, current, index) => {
            acc[results[index].subdomain] = current;
            return acc;
        }, {});
    });
};

/**
 * Generate the complete CQ filters and sort for the given view settings.
 *
 * @param {object} viewSettings - table card specific view settings
 * @param {object} baseData - dynamic filter base DEIM data containing all reference fields
 * @returns {Array} An array of filters, sort and domain info per filter criteria, or null.
 * Note that ```[undefined, undefined, undefined]``` can be returned and is a valid filter criteria
 * when no specific filter and/or sort is defined.
 */
const buildFilterAndSortAry = (viewSettings, baseData) => {
    if (!Array.isArray(viewSettings.views) || viewSettings.views.length === 0) {
        return null;
    }

    let filterCriteria = viewSettings.views[0].filterCriteria;

    if (!Array.isArray(filterCriteria)) {
        filterCriteria = [filterCriteria];
    }

    const filterArray = filterCriteria.map((aFilter) => {
        let filterConfig, sortConfig, baseType;

        if (!aFilter) return [filterConfig, sortConfig, baseType];

        // Static filters
        if (aFilter.filters) {
            filterConfig = aFilter.filters;
        }

        // Dynamic filters
        if (aFilter.filterGroupByRef && Array.isArray(aFilter.filterGroupByRef) && 0 < aFilter.filterGroupByRef.length) {
            const filterGroupByRef = aFilter.filterGroupByRef[0]; // Default filter group
            filterConfig = mergeAllFilters(aFilter, genDynamicCQFilters(filterGroupByRef, baseData));
        }

        if (aFilter.sort) {
            sortConfig = aFilter.sort;
        }

        // Return the override type if configured
        if (aFilter.baseType) {
            baseType = aFilter.baseType;
        }

        return [filterConfig, sortConfig, baseType];
    });

    return filterArray;
};

/**
 * Simply merges the static filters with the dynamic filters.
 *
 * @param {object} filterCriteria - contains the static filter definition
 * @param {object} dynaFilters - the dynamic filter
 * @returns {object} Tobjecthe complete CQ filter
 */
const mergeAllFilters = (filterCriteria, dynaFilters) => {
    const staticFilters = getValueFromObj(filterCriteria, 'filters');
    return Object.assign({}, staticFilters, dynaFilters);
};

/**
 * Generate the dynamic filter given the input filter group configuration.
 *
 * @param {object} filterGroup - dynmic filter definition
 * @param {object} deimObj - referenced DEIM data
 * @returns {object} The CQ filter
 */
const genDynamicCQFilters = (filterGroup, deimObj) => {
    if (!filterGroup || !deimObj) return;

    const refDomainKeyPath = getValueFromObj(filterGroup, 'filterByRefDomainKey');
    let cqFilter;

    if (!refDomainKeyPath) return;

    const filterValArry = flattenFilterRefKeys(deimObj, refDomainKeyPath);
    const filterField = getValueFromObj(filterGroup, 'filterBy');

    if (filterField && !!filterValArry) {
        cqFilter = { [filterField]: filterValArry };
    }

    return cqFilter;
};

/**
 * Given an object path and the corresponding object,
 * construct an array with just the value(s).  The path leaf can be
 * an object with repeating keys, and all the corresponding values
 * will be returned.  Non-primitive path leaf types are returned as null.
 *
 * Examples,
 *```
 * object: { a: { b: { c: { d: 1 } } } }
 * path: 'a.b.c.d'
 * returns: [1]
 *
 * object: { a: { b: { c: [{ d: 1 }, { d: 2 }, { d: 3 }] } } }
 * path: 'a.b.c.[].d'
 * returns: [1, 2, 3]
 * ```
 *
 * @param {object} deimObj - object to extract ref key(s)
 * @param {string} refKeyPath - string object path to extract ref key(s)
 * @returns {Array<any>} array of object ref keys; otherwise, null/undefined
 */
const flattenFilterRefKeys = (deimObj, refKeyPath) => {
    if (!deimObj || !refKeyPath || '' === refKeyPath) return;

    const pathArray = refKeyPath.split('.[].');
    let filterArray;

    // We only handle one inner array, if any
    if (1 > pathArray.length || 2 < pathArray.length) return;

    if (1 === pathArray.length) {
        const val = getValueFromObj(deimObj, pathArray[0]);
        filterArray = (val || 0 === val) && 'object' !== typeof val ? [val] : null;
    } else if (2 === pathArray.length) {
        const tarry = getValueFromObj(deimObj, pathArray[0]);
        if (Array.isArray(tarry)) {
            const val = tarry.map((e) => e[pathArray[1]]);
            filterArray = 0 < val.length ? val : null;
        }
    }

    return filterArray;
};

export default getAttributesData;
