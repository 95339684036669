/**
 * @file Notifications Component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Menu, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { List, ListItem, Badge } from 'webcore-ux/react/components';
import classnames from 'classnames';
import { CheckMarkCircle, ErrorCircle } from 'webcore-ux/nextgen/components/Icons';
import { getDateTimeDurationString } from 'webcore-common/LocaleUtils';
import { useApiNotifications, useApiPreferences, useInterval } from '../../../hooks';
import { I18N_COMMON, NOTIFICATIONS, NOTIFICATION_TYPES, STORAGE_TYPES } from '../../../lib/constants';
import { StorageService } from '../../../services';
import PropTypes from 'prop-types';

export const IDS = {
    BTN_NOTIFICATIONS: 'notifications-button',
    BTN_MARK_READ: 'notifications-mark-read',
    PANEL: 'notifications-panel',
    TRIGGER: 'notifications-panel-button',
    NOTIFICATION_LIST: 'notifications-list',
    LIST_ITEM: 'notifications-list-item',
    LIST_ITEM_ICON: 'notifications-list-item-icon',
    LIST_ITEM_MESSAGE: 'notifications-list-item-message',
    LIST_ITEM_DATE: 'notifications-list-item-date',
};

const useStyles = makeStyles(() => ({
    iconButtonRight: {},
    notificationsMenu: {},
}));

const useMenuStyles = makeStyles((theme) => ({
    paper: {
        minWidth: 200,
        textAlign: 'center',
        backgroundColor: theme.palette.nearWhite.main,
    },
}));

const useNotificationStyles = makeStyles((theme) => ({
    listItem: {},
    btnMarkAsRead: {
        left: 5,
        position: 'absolute',
        top: 3,
    },
    notificationDate: {
        textAlign: 'right',
        width: 100,
    },
    notificationFlag: {
        height: 20,
        width: 20,
    },
    notificationFlagUnread: {
        backgroundColor: theme.palette.background,
    },
    notificationList: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 400,
        width: 400,
    },
    notificationListItem: {
        padding: 15,
    },
    notificationMessage: {
        paddingLeft: 10,
        width: 260,
    },
}));

const useBadgeStyles = makeStyles((theme) => ({
    badge: {
        color: theme.palette.nearWhite.main,
    },
}));

const storage = StorageService(STORAGE_TYPES.SESSION);

/**
 * Renders the notification menu button and menu
 * @param {number} fetchInterval - the fetch interval
 * @param {string} getStringResource - the string resource
 * @param {function} getToken - the get keycloak auth token function
 * @param {string} host - the base url host
 * @param {string} initiator - initiator email address
 * @param {number} offset - the offset value
 * @param {number} size - the size of displayed results
 * @returns {JSX.Element|null} - The component to render
 * @constructor
 */

export const Notifications = ({ fetchInterval = 30000, getStringResource, getToken, host, initiator, offset = 0, size = 10 }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const badgeClasses = useBadgeStyles(theme);
    const menuClasses = useMenuStyles(theme);
    const notificationClasses = useNotificationStyles(theme);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClickNotifications = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseNotifications = () => {
        setAnchorEl(null);
    };

    const [interval, setInterval] = useState(null);
    const { preferences, doFetchPreferences, setPreferences } = useApiPreferences(storage(NOTIFICATIONS.STORAGE_KEY) || {}, {
        getToken,
        host,
        id: NOTIFICATIONS.PREFERENCES_ID,
        initiator,
    });

    const { notifications, doFetchNotifications } = useApiNotifications([]);

    useEffect(() => {
        if (preferences && !preferences.notificationLastReadAt) {
            doFetchPreferences().then((configuration = {}) => {
                if (!configuration || !configuration.notificationLastReadAt) {
                    configuration.notificationLastReadAt = moment().subtract(1, 'year').toDate().toISOString();
                }

                storage(NOTIFICATIONS.STORAGE_KEY, configuration);

                setPreferences(configuration);

                doFetchNotifications({
                    getToken,
                    host,
                    initiator,
                    offset,
                    since: configuration.notificationLastReadAt,
                    size,
                }).then(() => {
                    setInterval(fetchInterval); // start interval polling
                });
            });
        } else {
            doFetchNotifications({ getToken, host, initiator, offset, since: preferences.notificationLastReadAt, size }).then(() => {
                setInterval(fetchInterval); // start interval polling
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const locales = useMemo(
        () => ({
            btnMarkAsRead: getStringResource(`notifications.btnMarkAsRead`),
            hourAndMinute: ({ hours, minutes }) =>
                getStringResource('refreshStatus.hourAndMinute', { count: hours, minutes, namespace: I18N_COMMON }),
            lessThanOneMin: getStringResource('refreshStatus.oneMinOrLess'),
            minute: ({ minutes }) => getStringResource('refreshStatus.minute', { count: minutes }),
            never: getStringResource('refreshStatus.never'),
            noNotifications: getStringResource(`notifications.noNotifications`),
            oneDayOrMore: getStringResource('refreshStatus.oneDayOrMore'),
        }),
        [getStringResource]
    );

    useInterval(() => {
        doFetchNotifications({ getToken, host, initiator, offset, since: preferences.notificationLastReadAt, size });
    }, interval);

    /**
     * Handles the notification message with/without parameters
     * @param {string} message - the notification message
     * @param {object} messageParameters - notification message parameters
     * @param {string} domain - the domain
     * @param {string} subDomain - the sub domain
     * @param {string} breadCrumbId - the breadcrumb id
     * @returns {String} the notification message
     */
    const getMessageWithParams = ({ message, messageParameters, domain, subDomain, breadCrumbId }) => {
        if (!messageParameters) {
            return message;
        }

        if (!message) {
            return `An error occurred in an operation to the ${domain}-${subDomain} subdomain. Please contact your system administrator. Error: ${breadCrumbId}`;
        }

        if (Object.keys(messageParameters).length === 0) {
            message = message.replace('{{}}', `the ${domain}-${subDomain} subdomain`);
        } else {
            Object.keys(messageParameters).forEach((key) => {
                message = message.replace('{{' + key + '}}', messageParameters[key]);
            });
        }

        return message;
    };

    const count = notifications && notifications.length ? notifications.length : 0;

    return (
        <>
            <IconButton
                className={classes.iconButtonRight}
                aria-controls="notifications-menu"
                aria-haspopup="true"
                onClick={handleClickNotifications}
                data-testid={IDS.BTN_NOTIFICATIONS}
            >
                <Badge badgeContent={count ? count : null} color={'red'} classes={badgeClasses}>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="notifications-menu"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseNotifications}
                className={classes.notificationsMenu}
                classes={menuClasses}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div>
                    <List component="nav" className={notificationClasses.notificationList} data-testid={IDS.NOTIFICATION_LIST}>
                        {count > 0 ? (
                            notifications.map((notification, i) => (
                                <ListItem
                                    button={false}
                                    className={notificationClasses.notificationListItem}
                                    data-testid={IDS.LIST_ITEM}
                                    data-uuid={notification.notificationUUID}
                                    key={`n-${i}`}
                                >
                                    <div className={classnames(notificationClasses.notificationFlag)} data-testid={IDS.LIST_ITEM_ICON}>
                                        {notification.notificationType === NOTIFICATION_TYPES.ERROR ? (
                                            <ErrorCircle color={'error'} fontSize={'small'} />
                                        ) : (
                                            <CheckMarkCircle color={'primary'} fontSize={'small'} />
                                        )}
                                    </div>
                                    <div className={notificationClasses.notificationMessage} data-testid={IDS.LIST_ITEM_MESSAGE}>
                                        {getMessageWithParams(notification)}
                                    </div>
                                    <div className={notificationClasses.notificationDate} data-testid={IDS.LIST_ITEM_DATE}>
                                        {getDateTimeDurationString(notification.createdDateTime, locales)}
                                    </div>
                                </ListItem>
                            ))
                        ) : (
                            <ListItem className={notificationClasses.listItem}>{locales.noNotifications}</ListItem>
                        )}
                    </List>
                </div>
            </Menu>
        </>
    );
};

Notifications.defaultProps = {
    fetchInterval: 10000,
    size: 10,
    offset: 0,
};

Notifications.propTypes = {
    /** Additional className */
    className: PropTypes.string,

    /** optional function to dispatch redux action */
    dispatch: PropTypes.func,

    /** If present, the hook will return the list of notifications for the domain-subdomain name. */
    domain: PropTypes.string,

    /** Interval defining how often (in milliseconds) the fetch should occur */
    fetchInterval: PropTypes.number,

    /** Function for getting an i18n string resource. Signature: getStringResource(key, options) */
    getStringResource: PropTypes.func.isRequired,

    /** Function used for authentication. Signature: getToken(authentication) */
    getToken: PropTypes.func.isRequired,

    /** Host name to send the fetch requests to */
    host: PropTypes.string.isRequired,

    /** Email of the user whose notifications are to be fetched */
    initiator: PropTypes.string.isRequired,

    /** Offset to skip any number of notifications from the start */
    offset: PropTypes.number,

    /** Size of the notifications to fetch in one go */
    size: PropTypes.number,
};

export default Notifications;
