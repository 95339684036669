/**
 * @file Load mask component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */

import React from 'react';
import classNames from 'classnames';
import { LoadingIndicator } from 'webcore-ux/nextgen/components';
import { makeStyles } from '@material-ui/core';

// JSS styling for this component
const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        'z-index': 9999,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: 'rgb(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
    },
}));

/**
 * Load Mask component
 * @returns {JSX.Element} LoadMask component
 */
const LoadMask = () => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.root)} data-testid="de-cmn-next-loadmask-container">
            <LoadingIndicator size="large" />
        </div>
    );
};

export default LoadMask;
