import { send } from 'webcore-common/Send';

/**
 * Request for workbench data
 *
 * @param {number} page - page index
 * @param {number} pageSize - page size
 * @param {object} filters - filters object
 * @param {Array<any>} sort - sort array
 * @param {object} getToken - authentication promise
 * @param {string} domain - main type
 * @param {string} subdomain - sub type
 * @param {baseUrl} baseUrl - base URL
 * @returns {Promise} promise
 */
export const getCQWorkbenchData = (page, pageSize, filters, sort, getToken, domain, subdomain, baseUrl) => {
    const payload = constructPayload(page, pageSize, filters, sort);

    const listViewUrl = `${baseUrl}/v1/cq/${domain}/${subdomain}-de.${domain}.${subdomain}.index-1.0.0.json`;

    const getWorkbenchTableDataPromise = new Promise((resolve, reject) => {
        send('POST', listViewUrl, payload, getToken).then(
            (responseText) => {
                try {
                    let data = JSON.parse(responseText);
                    resolve(data);
                } catch (ex) {
                    reject(`Error getting workbench table data. ex: ${ex}`);
                }
            },
            (error) => {
                reject(`Error getting workbench table data. Error: ${error}`);
            }
        );
    });

    return Promise.all([getWorkbenchTableDataPromise]);
};

/**
 * Construct the payload data for API call based upon the workbench card.
 *
 * @param {number} from - page size to retrieve
 * @param {number} pageSize - page size to retrieve
 * @param {object} filters - page size to retrieve
 * @param {Array<any>} sort - page size to retrieve
 *
 * @returns {object} payload - payload submitted to backend
 */
export const constructPayload = (from, pageSize, filters, sort) => {
    const payload = {
        from: 0,
        size: 28,
        filters: {},
        sort: [],
    };

    if (typeof from === 'number') payload.from = from;

    if (typeof pageSize === 'number') payload.size = pageSize;

    if (filters) payload.filters = filters;

    if (Array.isArray(sort)) payload.sort = sort;

    return payload;
};
