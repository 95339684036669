/**
 * @file WidgetDataGrid component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { DataGrid } from 'webcore-ux/nextgen/components';
import Logger from 'abb-webcore-logger/Logger';
import ProgressBar from 'webcore-ux/nextgen/components/ProgressBar/ProgressBar';

// JSS styling for this component
const useStyles = makeStyles({
    dataGrid: {
        height: '95%', // need to define
    },
});

/**
 * Hook used to fetch grid data
 * @param {function} getData - get data pending promise
 * @returns {object} - list of data and doFetchData function

 */
const useGetData = (getData) => {
    const [dataResult, setDataResult] = useState([]);

    const doFetchData = useCallback(async () => {
        try {
            const data = await getData();

            if (!data.results) {
                throw new Error('results object not found in response');
            }

            setDataResult(data.results);
            return data;
        } catch (err) {
            Logger.error(`Failed to retrieve widget grid data: ${err}`);
        }
    }, [getData]);

    return { dataResult, doFetchData, setDataResult };
};

/**
 * Grid component
 * @param {object} props - props for the grid component
 * @returns {ReactElement} - Grid component
 */

export const WidgetDataGrid = (props) => {
    const {
        className,
        getData,
        columns,
        minRows,
        columnOrder,
        onColumnsRearranged,
        canRearrangeColumns,
        onClick,
        onDoubleClick,
        dir,
        rowSelectOptions,
        rowSpacing,
        itemsPerPage,
        itemsPerPageOptions,
        pageSiblingCount,
        onChangePage,
        onChangeItemsPerPage,
        paginationLocales,
        totalItems,
        manual,
        simplifiedPagination,
    } = props;

    const classes = useStyles();

    const { dataResult, doFetchData } = useGetData(getData);

    useEffect(() => {
        doFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!dataResult.length) {
        return (
            <div>
                <ProgressBar data-testid={'de-cmn-widget-data-grid-progress-bar'} />
            </div>
        );
    }

    return (
        <div data-testid="de-cmn-nxt-widget-datagrid" id="de-cmn-nxt-widget-datagrid">
            <DataGrid
                data-testid="datagrid"
                className={classNames(classes.dataGrid, className)}
                data={dataResult}
                columns={columns}
                itemsPerPage={itemsPerPage}
                minRows={minRows}
                columnOrder={columnOrder}
                onColumnsRearranged={onColumnsRearranged}
                canRearrangeColumns={canRearrangeColumns}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                dir={dir}
                rowSelectOptions={rowSelectOptions}
                rowSpacing={rowSpacing}
                itemsPerPageOptions={itemsPerPageOptions}
                pageSiblingCount={pageSiblingCount}
                onChangePage={onChangePage}
                onChangeItemsPerPage={onChangeItemsPerPage}
                paginationLocales={paginationLocales}
                totalItems={totalItems}
                manual={manual}
                simplifiedPagination={simplifiedPagination}
            />
        </div>
    );
};

WidgetDataGrid.propTypes = {
    /** CSS class name of the root element */
    className: PropTypes.string,
    /** promise function to get data */
    getData: PropTypes.func.isRequired,
    /** Defines the grid columns. For a full list of properties see https://www.npmjs.com/package/react-table#columns */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            /**
             * The column ID, which should be unique. This is used to maintain an internal column membership which determines whether the table scroll positions are
             * reset on render.
             */
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            /**
             * Column type. May affect how cells are rendered, i.e. display numbers and dates in the current locale,
             * display booleans as checkboxes, and display values as hyperlinks.
             */
            columnType: PropTypes.oneOf([
                'string',
                'number',
                'duration',
                'boolean',
                'datetime',
                'date',
                'time',
                'hyperlink',
                'listitem',
                'tristate',
                'error',
                'arrayList',
                'reference',
                'node',
            ]),
            /** Field containing the text to display for the hyperlink. Valid for columnType 'hyperlink'. */
            hyperlinkDisplayTextField: PropTypes.string,
            /**
             * If supplied, this enables inline editing of the cells in the column.
             * Signature: ({ row: object, column: object, value: any }) -> undefined
             */
            onChange: PropTypes.func,
            /**
             * Checks if a field has an error. If the provided function returns a string (error message), DataGrid displays
             * error styling on the cell and adds a tooltip with the message.
             * If there are multiple statuses, the order of precedence is: Error > Warning > Success > Info
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getError: PropTypes.func,
            /**
             * Same as getError, but a warning instead.
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getWarning: PropTypes.func,
            /**
             * Same as getError, but a success message instead.
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getSuccess: PropTypes.func,
            /**
             * Same as getError, but an info message instead.
             * Signature: ({ row: object, column: object, value: any }) -> string
             */
            getInfo: PropTypes.func,
        })
    ).isRequired,
    /**
     * The minimum number of rows to be displayed by the table. If 0 (default), the table only displays as many rows
     * as there is data.
     */
    minRows: PropTypes.number,
    /**
     * A ordering map object {columnId: index} for DataGrid to sort columns by. Has the same
     * shape as the object passed into onColumnsRearranged. If not supplied, this is managed internally.
     */
    columnOrder: PropTypes.object,
    /**
     * After dragging, this callback receives an object {columnId: index} of the new ordering.
     * Be sure to set canRearrangeColumns to true if using this.
     */
    onColumnsRearranged: PropTypes.func,
    /** Set true to enable column rearrangement. */
    canRearrangeColumns: PropTypes.bool,
    /** Handler to catch click event on row/column */
    onClick: PropTypes.func,
    /** Handler to catch double click event on row/column */
    onDoubleClick: PropTypes.func,
    /** Display direction. If displaying right to left, 'rtl' must be provided for DataGrid's column rearrangement to work properly */
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    /**
     * Options for selecting rows on the DataGrid. If supplied, a column containing checkboxes to select the rows will
     * be displayed, and selection controls will be enabled. The selection state is controlled only, so both selectedRows and
     * onSelectionChanged must be provided for correct functionality.
     */
    rowSelectOptions: PropTypes.shape({
        /** An object shaped {[rowID]: true} where truthy entries determine the selected rows */
        selectedRows: PropTypes.object.isRequired,
        /** Signature: (selectedRows) => void, where selectedRows has the same shape as the selectedRows prop */
        onSelectionChanged: PropTypes.func.isRequired,
        /** The column ID whose data is used as the unique row ID */
        keyField: PropTypes.string.isRequired,
    }),
    /** Row sizing */
    rowSpacing: PropTypes.oneOf(['regular', 'condensed', 'relaxed']),
    // Pagination props
    /** The selected number of items per page. Used to calculate current item display range, and highlights the selection in the menu. */
    itemsPerPage: PropTypes.number,
    /** The list of options to display in the items per page menu */
    itemsPerPageOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            displayLabel: PropTypes.string,
        })
    ),
    /** How many pages to display either side of the current page */
    pageSiblingCount: PropTypes.number,
    /** datatest-id automation of the table */
    'data-testid': PropTypes.string,
    /**
     * Callback function that can be called when the page is changed
     * @param {Number} page - the new page number (page numbers start from 1)
     */
    onChangePage: PropTypes.func,
    /**
     * Callback function that can be called when the items per page change
     * @param {Number} page - The page number always resets to 1
     * @param {Number} itemsPerPage - The new value of the items per page
     */
    onChangeItemsPerPage: PropTypes.func,
    /** Localizable label text for the pagination component. For any of the properties not supplied, it will fall back to English. */
    paginationLocales: PropTypes.shape({
        itemsPerPage: PropTypes.string,
        goToPage: PropTypes.string,
        showing: PropTypes.string,
        of: PropTypes.string,
        ofMoreThan: PropTypes.string,
        page: PropTypes.string,
    }),
    /** The total items for the data grid pagination.
     *  Helpful when manual = true and more data can be fetched from the server so that pagination component displays the correct data
     */
    totalItems: PropTypes.number,
    /** Let the react-table know that data is server-side conrtolled by setting it to true*/
    manual: PropTypes.bool,
    /** Don't display anything related to amount of data in pagination component */
    simplifiedPagination: PropTypes.bool,
};

export default WidgetDataGrid;
