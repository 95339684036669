/**
 * @file Keycloak Settings module
 * @copyright © Copyright 2019 ABB. All rights reserved.
 * @module KeycloakSettings
 */


import { send } from "./Send";

import Logger from "abb-webcore-logger/Logger";

const keycloakSettingsStorageKey = 'keycloakSettingsCache';

/**
 * Retrieves/Loads the keycloak settings from the server, or loads the local cache, or rejects.
 *
 * @param {string} [urlPrefix] - The host name prefix
 * @param {boolean} [cacheResult] - Indicates if the result of the rest call should be cached
 * @return {Promise<object>} - The keycloak settings, or a reject if it fails to resolve.
 */
export function load(urlPrefix, cacheResult = true) {
    let url = 'keycloak_settings.json';

    if ((typeof urlPrefix === "string") && urlPrefix.length > 0) {
        url = `${urlPrefix}/${url}`;
    }

    // Get the keycloak settings from the host
    return send('GET', url, undefined, undefined).then( (result) => {
        let jsonResult = JSON.parse(result);
        if (cacheResult) {
            setCache(jsonResult);
        }

        return jsonResult;
    }).catch( (error) => {
        // Load the cache version on failure
        Logger.info(`There was an error getting the keycloak_settings.json file ${error}, falling back to the cached version, if any`);

        let keycloakSettingsStorage = getCache();

        try {
            if (keycloakSettingsStorage) {
                return JSON.parse(keycloakSettingsStorage);
            } else {
                throw new Error('There is no keycloak_settings cache');
            }
        } catch (error) {
            clearCache();
            Logger.error(`'The Keycloak Settings cannot be loaded from the server or the cache due to an error ${error}`);
            throw new Error('The Keycloak Settings cannot be loaded from the server or the cache.');
        }
    });
}

/**
 * Removes the keycloak cache
 */
export function clearCache() {
    localStorage.removeItem(keycloakSettingsStorageKey);
}

/**
 * Sets the cache, pass in the value as a JSON object and it will be stringified.
 * @param {object} keycloakSettings - Keycloak settings to cache.
 */
function setCache(keycloakSettings) {
    localStorage.setItem(keycloakSettingsStorageKey, JSON.stringify(keycloakSettings));
}

/**
 * Gets the cache as a JSON String.
 * @return {string} - the JSON string that was stored in local storage
 */
function getCache() {
    return localStorage.getItem(keycloakSettingsStorageKey);
}

export default {
    load,
    clearCache
};

