/**
 * @file useInterval hook
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import { useEffect, useRef } from 'react';

/**
 * Provides a declarative useInterval
 *
 * @param {function} fn - Function that will be called every `delay` ms.
 * @param {number} delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 * @param {boolean} immediate - Flag if the a function should run immediatly on init and then run in interval
 * @returns {void} -
 */

const useInterval = (fn, delay, immediate = false) => {
    const savedFnRef = useRef();

    useEffect(() => {
        savedFnRef.current = fn;

        if (immediate) {
            savedFnRef.current();
        }
    }, [fn, immediate]);

    useEffect(() => {
        if (delay !== null) {
            const intervalId = setInterval(savedFnRef.current, delay);
            return () => clearInterval(intervalId);
        }
    }, [delay]);
};

export default useInterval;
