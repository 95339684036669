/**
 * @file WidgetSelectorComponent
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */
import React from 'react';
import PropTypes from 'prop-types';
import WidgetKPIComponent from '../../WidgetKPI/WidgetKPI';
import WidgetCountComponent from '../../WidgetCount/WidgetCount';
import WidgetPieChart from '../../WidgetPieChart/WidgetPieChart';
import WidgetBarChart from '../../WidgetBarChart/WidgetBarChart';
import WidgetDataGrid from '../../WidgetDataGrid/WidgetDataGrid';
import WidgetAttachments from '../../WidgetAttachments/WidgetAttachments';
import WidgetAttributes from '../../WidgetAttributes/WidgetAttributes';
import { widgetTypes, widgetChartTypes } from '../../../constants';

/**
 * Component Function used to fetch proper card configuration object for GridCardLayout
 * @param {id} string - id used for component
 * @param {number | string} type - type of widget to return
 * @param {number | string} subtype - sub type of component to returnm such as chart.pie
 * @param {object} params - parameters to bind to child component returned
 * @returns {object} - a Card config object of {id, component} for GridCardLayout
 */

export const WidgetSelector = ({ id, type, subtype = null, params }) => {
    // following if statements are for backward compatability
    if (typeof type === 'string') {
        type = widgetTypes[type];
    }

    if (typeof subtype === 'string') {
        if (type === widgetTypes.chart) {
            subtype = widgetChartTypes[subtype];
        }
    }

    const getChartComponent = () => {
        switch (subtype) {
            case widgetChartTypes.pie:
                return React.cloneElement(<WidgetPieChart />, {
                    ...params.chartPie,
                    ...params.chartPie.events,
                });

            case widgetChartTypes.bar:
                return React.cloneElement(<WidgetBarChart />, {
                    ...params.chartBar,
                    ...params.chartBar.events,
                });
        }
    };

    const getComponent = () => {
        switch (type) {
            case widgetTypes.kpi:
                return React.cloneElement(<WidgetKPIComponent />, {
                    ...params.kpi,
                    ...params.kpi.events,
                });

            case widgetTypes.count:
                return React.cloneElement(<WidgetCountComponent />, {
                    ...params.count,
                    ...params.count.events,
                });

            case widgetTypes.chart:
                return getChartComponent(subtype);

            case widgetTypes.dataGrid:
                return React.cloneElement(<WidgetDataGrid />, {
                    ...params.dataGrid,
                });

            case widgetTypes.attachments:
                return React.cloneElement(<WidgetAttachments />, {
                    ...params.attachments,
                    ...params.attachments.events,
                });

            case widgetTypes.attributes:
                return React.cloneElement(<WidgetAttributes />, {
                    ...params.attributes,
                    ...params.attributes.events,
                });
        }
    };

    return {
        id,
        component: getComponent(),
    };
};

WidgetSelector.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    subtype: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    params: PropTypes.object,
};
