/**
 * @file Component for displaying attributes of a data model, eg. Asset attributes
 * © Copyright 2021 Hitachi ABB Power Grids Ltd. All rights reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { getValueFromObj } from 'webcore-common';
import { Form } from 'webcore-ux/nextgen/components';
import ReadOnlyField from 'webcore-ux/nextgen/components/Form/ReadOnlyField';
import { FormContext, generateRecordFromInline, Record } from 'webcore-ux/nextgen/components/FormBuilder/';

// Apply padding within the iframe, rather than outside of it, to prevent some overflowing elements (like checkbox hover) from being cut off
const PaddingContainer = styled.div`
    ${({ theme }) => `
        padding: ${theme.spacing(3)}px;
        padding-top: ${theme.spacing(1)}px;
    `}
`;

const ModelAttributes = ({
    data,
    getStringResource,
    config,
    configData,
    error,
    isEditMode,
    recordConfig: recordConfigProp,
    record: recordProp,
    getReferenceControl,
    hostUrl,
    getToken,
    onFormValueChange,
    validation,
}) => {
    if (error) {
        return <PaddingContainer data-testid="error-message">{getStringResource(error)}</PaddingContainer>;
    }

    const callbacks = {
        reference: {
            // eslint-disable-next-line react/display-name
            onRender: (props) => {
                if (typeof getReferenceControl === 'function') {
                    return getReferenceControl(props);
                }

                const { config: nodeConfig } = props;
                const displayValue = getValueFromObj(data, nodeConfig.field.displayPath) || getValueFromObj(data, nodeConfig.field.path);

                return (
                    <ReadOnlyField label={getStringResource(nodeConfig.label)} getStringResource={getStringResource}>
                        {displayValue}
                    </ReadOnlyField>
                );
            },
        },
    };

    const recordConfig = recordConfigProp || (configData.records && configData.records[config.record]) || generateRecordFromInline(config);
    const record = recordProp || new Record('AttributesRecord', recordConfig, data, false);
    const context = new FormContext('ModelAttributes');

    return (
        <PaddingContainer>
            <Form
                config={config}
                configData={configData}
                record={record}
                getStringResource={getStringResource}
                ref={context.formRef}
                callbacks={callbacks}
                isReadOnly={!isEditMode}
                hostUrl={hostUrl}
                getToken={getToken}
                onFormValueChange={onFormValueChange}
                validation={validation}
            />
        </PaddingContainer>
    );
};

ModelAttributes.defaultProps = {
    getStringResource: (string) => string,
    title: 'Attributes',
    configData: {},
    isEditMode: false,
};

ModelAttributes.propTypes = {
    /** Error path or label that displays when the form fails to render (determined by the consumer) */
    error: PropTypes.string,
    /** Data model to display key values */
    data: PropTypes.object,
    /**
     * JSON configuration for which fields to display. For more information see the Form Configuration Language
     * https://pgga-es.atlassian.net/wiki/spaces/EDT/pages/50237057/Form+Configuration+Language
     */
    config: PropTypes.shape({
        record: PropTypes.string, // Name of the record data entry
        children: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    /**
     * Eg. Data for list items and record definition
     */
    configData: PropTypes.shape({
        records: PropTypes.object,
    }),
    /**
     * Callback that takes a path string and returns the internationalized locale.
     * Signature: (String) => String
     */
    getStringResource: PropTypes.func,
    /**
     * Whether to show a button that allows expanding the card to the screen size. For this to work, ModelAttributes must be
     * wrapped in a card context provider.
     */
    showMaximizeButton: PropTypes.bool,
    /** Toggle the internal form to be editable or not */
    isEditMode: PropTypes.bool,
    /** The JSON object that defines the databinding structure.
     * If not supplied, ModelAttributes will attempt to generate one from the config.
     */
    recordConfig: PropTypes.object,
    /**
     * Databinding object
     * If not supplied, ModelAttributes will create one, but likely you should supply this object to access edited/databound values for submission.
     */
    record: PropTypes.object,
    /**
     * A custom render function for reference control types (eg. control type that queries foreign models and displays them in a dropdown).
     * The default output for references in ModelAttributes currently only supports view mode.
     * ({ config: object, error }) -> React.node
     */
    getReferenceControl: PropTypes.func,
    /** The host URL to eg. request data to populate dropdown */
    hostUrl: PropTypes.string,
    /** Callback for getting auth token. Should return Promise<string> */
    getToken: PropTypes.func,
    /** Function for listening to any form on changes, returning the name of the field and the new value */
    onFormValueChange: PropTypes.func,
    /** Form/field validation */
    validation: PropTypes.object,
};

export default ModelAttributes;
