/**
 * @file HeaderRightActionBar Component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders the banner right action bar content component
 * @returns {JSX.Element|null} - The component to render
 * @constructor
 */

export const HeaderRightActionBar = (props) => {
    const { profileComponent, notificationComponent } = props;

    return (
        <>
            {notificationComponent}
            {profileComponent}
        </>
    );
};

HeaderRightActionBar.propTypes = {
    /** Profile component **/
    profileComponent: PropTypes.node,
    /** Notifications Component **/
    notificationComponent: PropTypes.node,
};

export default HeaderRightActionBar;
