/**
 * @file i18next LocaleLoader module
 * @copyright © Copyright 2020 ABB. All rights reserved.
 * @module LocaleLoader
 */

import LngDetector from 'i18next-browser-languagedetector';
import XHRBackend from './I18nextBackend';
import BackendAdapter from './I18nextBackendAdapter';
import Dexie from 'dexie';

// i18next.init and i18next.reloadResources will both call the ajax function passed during 18next.init.
// However scoping issues unallow us to set a variable within the ajax function, so we set it outside of init with useBackend.
export let useBackend = false;

export function setUseBackend(value) {
    useBackend = value;
}

// i18next offline indexedDb
const localizationDB = new Dexie('LocalizationDB');
localizationDB.version(1).stores({ i18NextOfflineLocale: 'language' });

/**
 * Sets up i18next with backend to get additional locale.
 *
 * @param {object} i18next - i18next instance.
 * @param {function} getToken - Function that returns a Promise which resolves the token.
 * @param {array} namespaces - Namespaces of locales needed to be fetched from server.
 * @param {object} options - Additional options used when initializing i18next.
 * @param {string} [options.urlPrefix] - Domain name of the config api calls.
 * @param {function} [options.callback] - Callback called after i18next is initialized with no errors.
 * @param {object} [options.locales] - Locales/resources to be added to i18next instance.
 * @param {boolean} [options.useBackend] - Set to true to call backend on init. Defaulted to false because projects most likely only need local strings loaded on init until keycloak/getToken is loaded.
 * @returns {Promise} promise
 */
export function setupI18next(i18next, getToken, namespaces = [], options = {}) {
    const { urlPrefix = '', callback, locales } = options;

    if (!i18next) {
        throw new Error('must provide the i18next instance to setupI18next');
    }

    if (typeof getToken !== 'function') {
        throw new Error('getToken must be provided as a function to setupI18next');
    }

    setUseBackend(!!options.useBackend);

    return new Promise((resolve, reject) => {
        i18next
            .use(BackendAdapter)
            .use(LngDetector)
            .init(
                {
                    fallbackLng: 'en-BASE',
                    debug: false,
                    detection: {
                        order: ['querystring', 'cookie', 'navigator', 'localStorage', 'htmlTag', 'path', 'subdomain'],
                    },
                    // cloning because i18next modifies original array with additional namespaces from i18next.addResourceBundle
                    ns: [...namespaces],
                    backend: {
                        backend: XHRBackend,
                        backendOption: {
                            loadPath: `${urlPrefix}/v1/i18n/resources?lng={{lng}}&ns={{ns}}`,
                            allowMultiLoading: true,
                            ajax: (url, backendOptions, ajaxCallback) => {
                                if (useBackend) {
                                    return fetchLocales(getToken, url, ajaxCallback);
                                } else {
                                    ajaxCallback('{}', {});
                                }
                            },
                        },
                    },
                },
                (error) => {
                    loadLocales(i18next, locales);

                    i18nextCallback(resolve, reject, error, callback);
                }
            );
    });
}

/**
 * Function that handles fetching locales from server. This function replaces the default function used in "i18next-xhr-backend.
 *
 * @param {function} getToken - Function that returns a Promise which resolves the token.
 * @param {string} url - Url to fetch locales.
 * @param {function} callback - Callback passed from "i18next-xhr-backend" at the end of ajax call.
 * @returns {Promise} promise
 */
export function fetchLocales(getToken, url, callback) {
    return getToken()
        .then((token) => {
            if (token) {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url);
                xhr.timeout = 60000;
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                xhr.setRequestHeader('Accept', 'application/json');
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader('Cache-Control', 'no-cache');

                xhr.onload = function () {
                    // Store the localization in indexedDB for offline usage
                    try {
                        let data = JSON.parse(xhr.responseText);
                        const translationMap = new Map();

                        for (const key in data) {
                            translationMap.set(key, { language: key, configuration: data[key] });
                        }

                        localizationDB.transaction('rw', localizationDB.i18NextOfflineLocale, () => {
                            localizationDB.i18NextOfflineLocale.bulkPut(Array.from(translationMap.values()));
                        });
                    } catch (e) {
                        throw new Error("Failed to add i18next offline translations to indexedDB");
                    }

                    callback(xhr.responseText, xhr);
                };

                xhr.onerror = function () {
                    callback(null, null, 'An error occurred while fetching locales. Please refresh your browser and try again.');
                };

                xhr.ontimeout = function () {
                    callback(null, null, 'Locales took too long fetch. Please refresh your browser and try again.');
                };

                xhr.send();
            } else {
                callback(null, null, 'Token not available when calling setupI18next');
            }
        })
        .catch((err) => {
            callback(null, null, err);
        });
}

/**
 * Handles reloading i18next backend locales
 *
 * @param {object} i18next - i18next instance.
 * @param {array|string} [languages] - Languages that will be reloaded.
 * @param {array|string} [namespaces] - Namespaces that will be reloaded.
 * @param {function} [callback] - Callback called after i18next.reloadResources is called with no errors.
 * @returns {Promise} promise
 */
export function reloadBackendLocales(i18next, languages = null, namespaces = null, callback) {
    if (!i18next) {
        throw new Error('must provide the i18next instance to reloadBackendLocales');
    }

    setUseBackend(true);

    return new Promise((resolve, reject) => {
        i18next.reloadResources(languages, namespaces, (error) => {
            i18nextCallback(resolve, reject, error, callback);
        });
    });
}

/**
 * Handles fetching and loading namespaces to i18next.
 *
 * @param {object} i18next - i18next instance.
 * @param {array|string} namespaces - Namespaces that will be fetched and loaded from backend.
 * @param {function} [callback] - Callback called after i18next.loadNamespaces is called with no errors.
 * @returns {Promise} promise
 */
export function loadNamespaces(i18next, namespaces, callback) {
    if (!i18next) {
        throw new Error('must provide the i18next instance to loadNamespaces');
    }

    if (!namespaces) {
        throw new Error('must provide namespace(s) to loadNamespaces');
    }

    setUseBackend(true);

    return new Promise((resolve, reject) => {
        i18next.loadNamespaces(namespaces, (error) => {
            i18nextCallback(resolve, reject, error, callback);
        });
    });
}

/**
 * Callback after i18next methods called.
 *
 * @param {function} resolve - error passed from i18next methods.
 * @param {function} reject - error passed from i18next methods.
 * @param {array|string|undefined} error - error passed from i18next methods.
 * @param {function} [callback] - Callback called after i18next.loadNamespaces is called with no errors.
 */
export function i18nextCallback(resolve, reject, error, callback) {
    if (error) {
        let errMsg = error;
        if (Array.isArray(error)) {
            // By default, if an error occurs and multiple namespaces are loaded in i18next, that error is duplicated multiple times in an array.
            // As we only want to show the error once, we pass the first index instead of the whole array.
            errMsg = error[0];
        }

        reject(errMsg);
    } else {
        callback && callback();
        resolve();
    }
}

/**
 * Handles adding i18next locale bundles
 *
 * @param {object} i18next - i18next instance.
 * @param {object} locales - Locales/resources to be added to i18next instance.
 */
export function loadLocales(i18next, locales) {
    if (!i18next.addResourceBundle) {
        // i18next.addResourceBunle may not be a function if i18next did not init successfully
        return;
    }

    // Retrieve value stored in indexedDb to enable offline localization capability
    localizationDB.i18NextOfflineLocale.count().then((count)=>{
        if (count) {
            localizationDB.i18NextOfflineLocale.each(locale => {
                for (const namespace in locale.configuration) {
                    i18next.addResourceBundle(locale.language, namespace, locale.configuration[namespace], true, true);
                }
            });
        }
    });

    if (locales) {
        for (const lng in locales) {
            for (const namespace in locales[lng]) {
                // params are lng, namespace, locales, deep, overwrite
                // deep as true will extend the existing locales so key/values are checked individually if object is nested
                // overwrite as false will not replace key values if they already exist. This behaviour is wanted because server locales are priority over local locales.
                i18next.addResourceBundle(lng, namespace, locales[lng][namespace], true, false);
            }
        }
    }
}

/**
 * Add translations to moment library that are not supported by default for different locales
 * NOTE: Please call this function only after i18next has already been instantiated. 
 * ** IMPORTANT **: Also, make sure in the webpack config that only one moment and i18next instance is used throughout the application otherwise these translations won't work properly.
 * Eg. 
 *  resolve: {
        ...
        alias: {
            ...
            i18next: path.resolve(__dirname, 'node_modules/i18next'),
            moment: path.resolve(__dirname, 'node_modules/moment'),
        },
    },
 * 
 * @param {object} moment - moment instance
 * @param {object} i18next - i18next instance
 */
export function setupMoment(moment, i18next) {
    if (!moment) {
        throw new Error('Must provide the moment instance to update locales data');
    }

    if (!i18next) {
        throw new Error('Must provide the i18next instance to get the translations');
    }

    // Get the duration labels object for the current language set in i18next
    // Should be present for languages other than English otherwise the labels will default to
    // the English labels provided by moment-duration-format.
    // Refer https://www.npmjs.com/package/moment-duration-format#extending-moments-locale-object for supported fields
    const durationLabels = i18next.t('app-common:momentDurationLabels', { returnObjects: true, defaultValue: {} });

    // Default labels and date formats for unsupported locales to override those of the parent locale.
    // Refer https://momentjscom.readthedocs.io/en/latest/moment/06-i18n/01-changing-locale/ for supported fields
    const defaultLabelsAndDateFormats =
        i18next.t('app-common:momentDefaultLabelsAndDateFormats', { returnObjects: true, defaultValue: {} }) || {};

    const lang = navigator.userLanguage || navigator.language;

    // Only define/update a locale if durationLabels or defaultLabelsAndDateFormats is provided, otherwise let moment handle it.
    if (
        (typeof durationLabels === 'object' && Object.keys(durationLabels).length > 0) ||
        (typeof defaultLabelsAndDateFormats === 'object' && Object.keys(defaultLabelsAndDateFormats).length > 0)
    ) {
        // Initialize duration labels for the current locale
        // If the current locale doesn't exist, create it.
        // Mostly used for locales not supported by moment by default eg. es-CL
        // Can also be a case if the locale is supported but was not yet loaded.
        if (!moment.locales().includes(lang)) {
            const { _abbr: abbr } = moment.localeData(lang);
            if (abbr !== lang) {
                // Need to send it as an object otherwise the locale won't be added
                let config = {};

                // If a locale is not defined, moment tries to get the closest locale info that matches the given locale.
                // Only add the parentLocale if the current lang starts with the abbr.
                // Eg. moment.localeData('es-CL') will return abbr as 'es'
                // but moment.localeData('abc') will return abbr as 'en' (default) since there is no matching parent locale for abc.
                // In this case we don't need to use the parentLocale info since it will be the default value.
                // Checking lowercase in case lang = en-AU but abbr = en-au.
                if (lang.toLowerCase().startsWith(abbr.toLowerCase())) {
                    // parentLocale is used to get default values from the parent locale
                    config = { parentLocale: abbr };
                }

                moment.defineLocale(lang, config);
            }
        }

        moment.updateLocale(lang, Object.assign({}, defaultLabelsAndDateFormats, durationLabels));
    }

    // Set the current locale explicitly to avoid potential issues.
    moment.locale(lang);
}

export default {
    setupI18next,
    reloadBackendLocales,
    setupMoment,
};
