import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const defaultPermissions = {
    applications: [],
    domains: [],
};

export const PermissionsContext = React.createContext(defaultPermissions);

export const UpdatePermissionsContext = React.createContext();

/**
 * creates a permissions context
 * @return {{permissions: {}}} - the permissions context
 */
export const usePermissionsContext = () => {
    return useContext(PermissionsContext);
};

/**
 * creates an update permissions context
 * @return {unknown} - the update permissions context
 */
export const useUpdatePermissionsContext = () => {
    return useContext(UpdatePermissionsContext);
};

/**
 * creates a permissions provider
 * @param {object} value - the permissions object
 * @param {node} children - the component children
 * @return {JSX.Element} - the permissions provider
 * @constructor
 */
export const PermissionsProvider = ({ value, children }) => {
    const [permissions, setPermissions] = useState(value || defaultPermissions);

    const updatePermissions = (newPermissions) => {
        setPermissions(newPermissions);
    };

    return (
        <PermissionsContext.Provider value={permissions}>
            <UpdatePermissionsContext.Provider value={updatePermissions}>{children}</UpdatePermissionsContext.Provider>
        </PermissionsContext.Provider>
    );
};

PermissionsProvider.propTypes = {
    /** permissions value for context **/
    value: PropTypes.object,
    /** component children **/
    children: PropTypes.node,
};
