/**
 * @file WidgetCount component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import theme from 'webcore-ux/nextgen/theme/theme';
import { useCallback } from 'react';
import Logger from 'abb-webcore-logger/Logger';
import Gradient from 'javascript-color-gradient';
import ProgressBar from 'webcore-ux/nextgen/components/ProgressBar/ProgressBar';
import i18next from 'i18next';

/**
 * Hook used to fetch Count metrics for the logged in user
 * @param {function} getData - get data pending promise
 * @returns {object} - list of Notifications and doFetchNotifications function
 */
const useWidgetCountMetric = (getData) => {
    const [countMetric, setCountMetric] = useState({});
    const [loading, setLoading] = useState(true);

    const doFetchCountMetric = useCallback(async () => {
        try {
            const data = await getData();

            setCountMetric(data);
        } catch (err) {
            Logger.error(`useWidgetCountMetric: Failed to retrieve widget count data: ${err}`);
            throw new Error(err.message);
        } finally {
            setLoading(false);
        }
    }, [getData]);

    return { countMetric, doFetchCountMetric, setCountMetric, loading };
};

const statusColors = {
    all: theme.palette.secondary.dark,
    pending: theme.palette.error.main,
    dispatched: theme.palette.info.main,
    'en route': theme.palette.warning.main,
    'on site': theme.palette.caution.main,
    completed: theme.palette.success.main,
    unacknowledged: theme.palette.grey['500'],
};

/**
 * creates a widget Count component
 * @param {string} countDisplayText - the count text to display
 * @param {promise} getData - a pending getData promise
 * @param {function} onClick - the on click handler
 * @param {object} colorGradientConfig - the color config object
 * @param {string} colorGradientConfig.gradientStops - the colors for the gradient (min 2)
 * @param {string} colorGradientConfig.highColor - the high color value for color gradient
 * @param {number} colorGradientConfig.maxValue - the high count value for color gradient
 * @returns {JSX.Element} the component
 * @constructor
 */
export const WidgetCount = ({ countDisplayText, getData, onClick, colorGradientConfig = {} }) => {
    const [error, setError] = useState(false);

    const colorGradient = new Gradient();
    if (colorGradientConfig.gradientStops && colorGradientConfig.gradientStops.length > 1 && colorGradientConfig.maxValue) {
        colorGradient.setGradient(...colorGradientConfig.gradientStops);
        colorGradient.setMidpoint(colorGradientConfig.maxValue);
    } else {
        colorGradient.setGradient('#000000', '#000000');
        colorGradient.setMidpoint(1);
    }

    const { countMetric, doFetchCountMetric, loading } = useWidgetCountMetric(getData);
    const { count } = countMetric;

    useEffect(() => {
        const effect = async () => {
            try {
                await doFetchCountMetric();
            } catch (e) {
                setError(true);
            }
        };

        effect();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useStyles = makeStyles((theme) => ({
        container: {
            width: '100%',
            height: '100%',
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
            color: statusColors[status.toLowerCase()],
            borderRadius: '4px',
            cursor: 'pointer',
        },
        count: {
            fontSize: '32px',
            padding: 0,
            margin: 0,
        },
        status: {
            padding: 0,
            margin: 0,
            color: '#333',
            fontWeight: 600,
            fontSize: '16px',
        },
        body: {
            padding: `${theme.spacing(1)}px 0`,
        },
    }));

    const classes = useStyles();

    if (error) {
        return <div />;
    }

    if (loading) {
        return (
            <div>
                <ProgressBar data-testid="de-cmn-widget-count-progress-bar" />
            </div>
        );
    }

    return (
        <div className={classes.container} data-testid={'de-cmn-widget-count-root'} onClick={onClick}>
            <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                    <Grid container className={classes.body}>
                        <Grid item lg={12} md={12} xs={12} data-testid={'de-cmn-widget-count-count'}>
                            {count >= 0 && (
                                <h3 className={classes.count} style={{ color: colorGradient.getColor(count || 1) }}>
                                    {count}
                                </h3>
                            )}
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} data-testid={'de-cmn-widget-count-display-text'}>
                            <p className={classes.status}>{i18next.t(countDisplayText)}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

WidgetCount.defaultProps = {
    onClick: () => {},
};

WidgetCount.propTypes = {
    /** label for count value **/
    countDisplayText: PropTypes.string,
    /** the fetch data pending promise object**/
    getData: PropTypes.object,
    /** Count onClick handler **/
    onClick: PropTypes.func,
    /** the color gradient config object **/
    colorGradientConfig: PropTypes.shape({
        /** the colors for the gradient (min 2) **/
        gradientStops: PropTypes.arrayOf(PropTypes.string),
        /** the max value of the gradient **/
        maxValue: PropTypes.number,
    }),
};

export default WidgetCount;
