/**
 * @file Profile Component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, IconButton, Avatar, Divider, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToAppOutlined';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ThirdPartyCreditsDialog from './ThirdPartyCreditsDialog';
import About from './About';
import { usei18nextContext } from '../contexts/i18nextContext';

const useStyles = makeStyles((theme) => ({
    avatar: {
        color: theme.palette.grey.main,
        fontSize: 13,
        height: 32,
        width: 32,
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderColor: theme.palette.grey.main,
    },
    profileMenu: {},
    profileMenuHeader: {},
    profileMenuHeaderContainer: {
        width: 340,
        textAlign: 'center',
        backgroundColor: theme.palette.nearWhite.main,
    },
    profileMenuAvatar: {
        color: theme.palette.grey.main,
        fontSize: 24,
        height: 48,
        width: 48,
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderColor: theme.palette.grey.main,
    },
    profileMenuAvatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    profileMenuUserName: {
        paddingBottom: theme.spacing(1),
    },
    profileMenuUserDetails: {
        paddingBottom: theme.spacing(1),
    },
    profileMenuListItemText: {
        fontWeight: 500,
    },
    profileMenuPaper: {
        width: 340,
        backgroundColor: theme.palette.common.nearWhite,
    },
}));

/**
 * Renders the profile menu button and menu
 * @param {string} appName - the name of the application
 * @param {string} appVersion - the version of the application
 * @param {object} currentUser - the current user object
 * @param {function} onClickSignOff - the signoff click handler
 * @param {object} thirdPartyJSON - the third party credits json object
 * @return {JSX.Element}- The component to render
 * @constructor
 */

export const ProfileMenu = ({ appName, appVersion, currentUser, onClickSignOff, thirdPartyJSON, onClickHelp }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isCreditsDialogOpen, setIsCreditsDialogOpen] = React.useState(false);

    const RenderListItemText = ({ ns, defaultText }) => {
        const i18next = usei18nextContext() || { t: () => {} };
        return <ListItemText primary={`${i18next.t(ns) || defaultText}`} classes={{ primary: classes.profileMenuListItemText }} />;
    };

    RenderListItemText.propTypes = {
        ns: PropTypes.string,
        defaultText: PropTypes.string,
    };

    /**
     * handles the click of the profile menu open button
     * @param {Event} event the click event
     */
    const handleClickProfile = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * closes the profile menu
     */
    const handleCloseProfile = () => {
        setAnchorEl(null);
    };

    /**
     * toggles the open state of the credits dialog
     */
    const toggleCreditsDialog = () => {
        handleCloseProfile();
        setIsCreditsDialogOpen(!isCreditsDialogOpen);
    };

    return (
        <>
            <IconButton
                aria-controls="de-cmn-profile-menu"
                aria-haspopup="true"
                onClick={handleClickProfile}
                data-testid={'de-cmn-profile-button'}
            >
                <Avatar className={classes.avatar} data-testid={'de-cmn-profile-avatar'}>
                    {currentUser.initials}
                </Avatar>
            </IconButton>
            <Menu
                id="de-cmn-profile-menu"
                data-testid={'de-cmn-profile-menu'}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseProfile}
                className={classes.profileMenu}
                classes={{ paper: classes.profileMenuPaper }}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div data-testid={'de-cmn-profile-menu-header'} className={classes.profileMenuHeader} onClick={handleCloseProfile}>
                    <div className={classes.profileMenuHeaderContainer}>
                        <div className={classes.profileMenuAvatarContainer} data-testid={'de-cmn-profile-menu-avatar'}>
                            <Avatar className={classes.profileMenuAvatar}>{currentUser.initials}</Avatar>
                        </div>
                        <div className={classes.profileMenuUserName} data-testid={'de-cmn-profile-menu-username'}>
                            <Typography variant={'h5'}>{currentUser.name}</Typography>
                        </div>
                        <div className={classes.profileMenuUserDetails} data-testid={'de-cmn-profile-menu-email'}>
                            <Typography variant={'subtitle2'}>{currentUser.email}</Typography>
                        </div>
                    </div>
                </div>

                <About appName={appName} version={appVersion} />

                <Divider />
                <MenuItem onClick={toggleCreditsDialog} data-testid={'de-cmn-profile-menu-credits-action'}>
                    <ListItemIcon>
                        <InfoOutlinedIcon />
                    </ListItemIcon>
                    <RenderListItemText ns={'app-common:mainMenu.creditsMenuItem'} defaultText={'Credits'} />
                </MenuItem>
                <Divider />
                {onClickHelp && (
                    <>
                        <MenuItem onClick={onClickHelp} data-testid={'de-cmn-profile-menu-help-action'}>
                            <ListItemIcon>
                                <HelpOutlineOutlinedIcon />
                            </ListItemIcon>
                            <RenderListItemText ns={'app-common:mainMenu.helpMenuItem'} defaultText={'Help'} />
                        </MenuItem>
                        <Divider />
                    </>
                )}
                <MenuItem onClick={onClickSignOff} data-testid={'de-cmn-profile-menu-signoff-action'}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <RenderListItemText ns={'app-common:mainMenu.signOffButton'} defaultText={'Sign Off'} />
                </MenuItem>
            </Menu>
            <ThirdPartyCreditsDialog
                appName={appName}
                isOpen={isCreditsDialogOpen}
                closeDialog={toggleCreditsDialog}
                thirdPartyJSON={thirdPartyJSON}
            />
        </>
    );
};

ProfileMenu.defaultProps = {
    currentUser: {},
    onClickSignOff: () => {},
    thirdPartyJSON: {},
};

ProfileMenu.propTypes = {
    /** the application name **/
    appName: PropTypes.string.isRequired,
    /** the application version **/
    appVersion: PropTypes.string.isRequired,
    /** The url relative sign out path to return to after sign out action **/
    signOutPath: PropTypes.string,
    /** a current user object **/
    currentUser: PropTypes.shape({
        email: PropTypes.string,
        initials: PropTypes.string,
        name: PropTypes.string,
    }),
    /** onClickSignOff menu item handler function **/
    onClickSignOff: PropTypes.func,
    /** onClickHelp menu item handler function **/
    onClickHelp: PropTypes.func,
    /** The third party json credits object **/
    thirdPartyJSON: PropTypes.object,
};

export default ProfileMenu;
