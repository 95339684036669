/**
 * @file i18next Modified I18next XHR Backend Plugin
 * @copyright © Copyright 2020 ABB. All rights reserved.
 * @module LocaleLoader
 */

import XHRBackend from 'i18next-xhr-backend';

export default class XHRBackendMod extends XHRBackend {

    constructor(services, options = {}) {
        super(services, options);
    }


    /**
     * Fetches locales from the server and calls the callback from i18next with data received.
     * 
     * @param {string} url - Url to fetch locales .
     * @param {function} callback - Function passed from i18next.
     */
    loadUrl(url, callback) {
        // This code is cloned from https://github.com/i18next/i18next-xhr-backend "src/index.js -> loadUrl". Version 3.2.2. Latest commit dc6e10e
        this.options.ajax(url, this.options, (data, xhr, error) => {
            // if statement below and error strings are the only modification to the original code
            // modification needed to be able to add error handling within LocaleLoader
            if (error) {
                return callback(error, false);
            }

            if (xhr && xhr.status >= 500 && xhr.status < 600) {
                return callback('App could not fetch locales due to a server error. Please refresh your browser and try again.', true /* retry */);
            }

            if (xhr && xhr.status >= 400 && xhr.status < 500) {
                return callback('App could not fetch locales due to a client error. Please refresh your browser and try again.', false /* no retry */);
            }

            let ret, parseError;

            try {
                ret = this.options.parse(data, url);
            } catch (e) {
                parseError = 'App could not parse locales from server. Please refresh your browser and try again.';
            }

            if (parseError) {
                return callback(parseError, false);
            } else {
                callback(null, ret);
            }
        });
    }
}