import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AttachmentThumbnail from 'webcore-ux/nextgen/components/AttachmentThumbnail/AttachmentThumbnail';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid/Grid';
import LoadingIndicator from 'webcore-ux/nextgen/components/LoadingIndicator/LoadingIndicator';
import ProgressBar from 'webcore-ux/nextgen/components/ProgressBar/ProgressBar';

const Theme = styled.div`
     {
        padding: 0;
        margin: 0;
        .thumbnail-image {
            margin: 10px;
        }
        .loader {
            margin-top: 100px;
        }
    }
`;

export const WidgetAttachments = ({ getData, loaderComponent }) => {
    const [attachments, setAttachments] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    if (!loaderComponent) {
        // eslint-disable-next-line react/display-name
        loaderComponent = () => <LoadingIndicator className="loader" size="small" />;
    }

    useEffect(() => {
        const effects = async () => {
            setIsLoading(true);

            try {
                const data = await getData();
                setAttachments(data);
            } catch (err) {
                setError(true);
                // eslint-disable-next-line no-console
                console.error(`WidgetAttachments: Failed to retrieve data: ${err}`);
            }

            setIsLoading(false);
        };

        effects();
    }, [getData]);

    if (error) {
        return <div />;
    }

    if (isLoading || !attachments || !attachments.length) {
        return (
            <div>
                <ProgressBar data-testid={'de-cmn-widget-attachments-progress-bar'} />
            </div>
        );
    }

    return (
        <Theme>
            <Grid container justify="flex-start" spacing={1}>
                {attachments.length > 0 &&
                    attachments.map((attachment, i) => {
                        return (
                            <Grid item key={`${attachment.fileName}-${i}`}>
                                <div className="thumbnail-image" data-testid={`wcux-attachment-item-${i}`}>
                                    <AttachmentThumbnail {...attachment} />
                                </div>
                            </Grid>
                        );
                    })}
            </Grid>
        </Theme>
    );
};

WidgetAttachments.propTypes = {
    getData: PropTypes.func,
    loaderComponent: PropTypes.func,
};

export default WidgetAttachments;
