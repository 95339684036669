/**
 * @file ThirdPartyCreditsDialog component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { default as Credits } from 'webcore-ux/react/components/Credits/Credits';
import { default as Dialog } from 'webcore-ux/nextgen/components/Dialog/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'webcore-ux/nextgen/components/Copyright/Copyright';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '892px',
        maxHeight: '446px',
        margin: 'auto auto',
        scrollbarColor: '#87ceeb',
    },
}));

/**
 * ThirdPartyCreditsDialog displays the third party json document of third party licenses.
 * @param {string} appName - the name of the application
 * @param {Boolean} isOpen - sets the state of the dialog
 * @param {Function}  closeDialog - function to close the dialog
 * @param {object} thirdPartyJSON - the json object structure to display
 * @returns {JSX.Element} - Component
 * @constructor
 */
export const ThirdPartyCreditsDialog = ({ appName, isOpen, closeDialog, thirdPartyJSON }) => {
    const classes = useStyles();
    return (
        <Dialog open={isOpen} onClose={() => closeDialog()} className={classes.root} disablePortal={true} title={appName}>
            <Credits data={thirdPartyJSON} />
            <Copyright />
        </Dialog>
    );
};

ThirdPartyCreditsDialog.defaultProps = {
    appName: 'unknown',
};

ThirdPartyCreditsDialog.propTypes = {
    /** application name **/
    appName: PropTypes.string,
    /** open state of dialog **/
    isOpen: PropTypes.bool,
    /** onClose handler to close dialog **/
    closeDialog: PropTypes.func,
    /** the json object to display **/
    thirdPartyJSON: PropTypes.object,
};

export default ThirdPartyCreditsDialog;
