/**
 * @file MenuContent Component
 * @copyright © Copyright 2022 Hitachi Energy. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usei18nextContext } from '../../contexts/i18nextContext';
import { useMenuItemProps } from '../../contexts/RouteContext';
import { TrayMenu } from 'webcore-ux/nextgen/components/MenuTray/TrayMenu/TrayMenu';

import Home from 'webcore-ux/nextgen/components/Icons/HomeTwoTone';
import Work from 'webcore-ux/nextgen/components/Icons/BuildTwoTone';
import ListAlt from 'webcore-ux/nextgen/components/Icons/ListAltTwoTone';
import LocalAtm from 'webcore-ux/nextgen/components/Icons/LocalAtmTwoToneIcon';
import Help from 'webcore-ux/nextgen/components/Icons/HelpTwoTone';
import Model from 'webcore-ux/nextgen/components/Icons/Model';
import Language from 'webcore-ux/nextgen/components/Icons/Language';
import Analytics from 'webcore-ux/nextgen/components/Icons/Analytics';
import Map from 'webcore-ux/nextgen/components/Icons/PlaceTwoTone';
import Admin from 'webcore-ux/nextgen/components/Icons/Admin';
import Authorization from 'webcore-ux/nextgen/components/Icons/Authorization';
import Replay from 'webcore-ux/nextgen/components/Icons/Replay';
import Scheduling from 'webcore-ux/nextgen/components/Icons/Scheduling';
import ShoppingCart from 'webcore-ux/nextgen/components/Icons/ShoppingCartTwoTone';
import Fmeca from 'webcore-ux/nextgen/components/Icons/Fmeca';
import ReliabilityModeler from 'webcore-ux/nextgen/components/Icons/ReliabilityModeler';
import { unsecuredApps } from '../../../constants';
import { getMenuConfig } from '../../../../services/getMenuConfig';

/**
 * return an icon looked up by name
 * @param {string} code - the icon name
 * @returns {JSX.Element|null} - the selected icon
 */
const getMenuIconByAppCode = (code) => {
    switch (code) {
        case 'homeapp':
            return <Home />;
        case 'workorderapp':
            return <Work />;
        case 'aip':
            return <LocalAtm />;
        case 'listapp':
            return <ListAlt />;
        case 'helpapp':
            return <Help />;
        case 'modelapp':
            return <Model />;
        case 'languageapp':
            return <Language />;
        case 'analyticsapp':
            return <Analytics />;
        case 'mapapp':
            return <Map />;
        case 'wfmadminapp':
            return <Admin />;
        case 'replayapp':
            return <Replay />;
        case 'authzapp':
            return <Authorization />;
        case 'schedulingapp':
            return <Scheduling />;
        case 'catalogapp':
            return <ShoppingCart />;
        case 'fmeca':
            return <Fmeca />;
        case 'reliabilitymodeler':
            return <ReliabilityModeler />;
        default:
            return null;
    }
};

/**
 * verifies user has required domain permission
 * @param {object[]} subdomains - list of available subdomains
 * @param {string} searchDomain - domain to search for
 * @returns {*} - true / false for domain validation
 */
const hasDomainPermission = (subdomains, searchDomain) => {
    return subdomains.some((subdomain) => subdomain.domain === searchDomain && subdomain.view);
};

/**
 * verifies user has required subdomain permission
 * @param {object[]} subdomains - list of available subdomains
 * @param {string} searchSubDomain - subdomain to search for
 * @returns {*} - true / false for subdomain validation
 */
const hasSubDomainPermission = (subdomains, searchSubDomain) => {
    return subdomains.some((subdomain) => subdomain.subdomain === searchSubDomain && subdomain.view);
};

/**
 * recursive transformation function for building out menuConfig
 * @param {object} menuItem - the original menuItem object
 * @param {object} i18next - the i18next translation object
 * @param {object[]} applications - all applications object
 * @param {object[]} subdomains - all subdomain object
 * @param {string} activatedApp - the activated application string
 * @param {string} helpUrl - the help url (app specific)
 * @param {string} onClick - the onClick for route
 * @returns {{displayText: (*|string|string)}} - the transformed config
 */
const transformMenuItem = (menuItem, i18next, applications, subdomains, activatedApp, helpUrl, onClick) => {
    if (
        (!menuItem.domain || hasDomainPermission(subdomains, menuItem.domain)) &&
        (!menuItem.subdomain || hasSubDomainPermission(subdomains, menuItem.subdomain))
    ) {
        const menuItemTransformed = {
            displayText: i18next.t(menuItem.displayText) || menuItem.displayText,
        };

        if (menuItem.code) {
            menuItemTransformed.code = menuItem.code;
            menuItemTransformed.activated = activatedApp === menuItem.code;
            menuItemTransformed.iconText = i18next.t(menuItem.iconText) || menuItem.iconText;
            menuItemTransformed.icon = getMenuIconByAppCode(menuItem.code);
            menuItemTransformed.newWindow = menuItem.newWindow;
        }

        if (menuItem.link) {
            menuItemTransformed.link = menuItem.code === 'helpapp' ? helpUrl : menuItem.link;
        }

        if (onClick) {
            menuItemTransformed.onClick = onClick;
        }

        if (menuItem.childMenuItems && menuItem.childMenuItems.length) {
            menuItemTransformed.childMenuItems = [];
            menuItem.childMenuItems.forEach((childMenuItem) => {
                const menuItemTemp = transformMenuItem(childMenuItem, i18next, applications, subdomains, activatedApp, helpUrl, onClick);
                if (menuItemTemp) {
                    menuItemTransformed.childMenuItems.push(menuItemTemp);
                }
            });
        }

        return menuItemTransformed;
    }
};

/**
 * transform a menu config object
 * @param {object} menuConfigJson - menu config object json
 * @param {object} i18next - i18next object
 * @param {object[]} applications - list of applications available to user
 * @param {object[]} domains - list of domains available to user
 * @param {string} activatedApp - the currently activated app
 * @param {string} helpUrl - the app specific help url
 * @param {string} onClick - the onClick for route
 * @returns {{}} - the transformed menu config
 */
const transformMenuConfig = (menuConfigJson, i18next, applications, domains, activatedApp, helpUrl, onClick) => {
    if (!menuConfigJson.global) return {};

    // add default unsecured applications (global access)
    const allApplications = (applications || []).concat(
        unsecuredApps.map((appCode) => {
            return { code: appCode };
        })
    );

    // flatten domain permission object
    const subdomains = [];
    (domains || []).forEach((domain) => {
        if (domain.subdomains) {
            domain.subdomains.forEach((subdomain) => {
                subdomains.push({
                    domain: domain.name,
                    subdomain: subdomain.name,
                    view: subdomain.view,
                });
            });
        }
    });

    const transformedMenuConfig = {
        global: {
            moreLinkText: i18next.t(menuConfigJson.global.moreLinkText) || menuConfigJson.global.moreLinkText,
        },
        menuSections: [],
    };

    const menuSections = menuConfigJson.menuSections;
    menuSections.forEach((menuSection) => {
        const transformedMenuSection = {
            sectionTitleText: i18next.t(menuSection.sectionTitleText) || menuSection.sectionTitleText,
            menuItems: [],
        };

        const menuSectionItems = menuSection.menuItems;

        // filter out possible menu items based on access
        const authorizedMenuSectionItems = menuSectionItems.filter((menuSectionItem) => {
            if (menuSectionItem['code']) {
                if (allApplications.filter((application) => application.code === menuSectionItem['code']).length) {
                    return true;
                }
            } else {
                return true;
            }
        });

        authorizedMenuSectionItems.forEach((menuItem) => {
            const menuItemTransformed = transformMenuItem(menuItem, i18next, allApplications, subdomains, activatedApp, helpUrl, onClick);

            if (menuItemTransformed) {
                transformedMenuSection.menuItems.push(menuItemTransformed);
            }
        });

        transformedMenuConfig.menuSections.push(transformedMenuSection);
    });

    return transformedMenuConfig;
};

/**
 * Renders the MenuContent component
 * @param {string} activatedApp - the activated menu item app
 * @param {object[]} applications - array of application objects
 * @param {object[]} domains - array of domain objects
 * @param {object} settings - the settings object
 * @param {function} getToken - the get token function
 * @returns {JSX.Element} - The rendered component
 * @constructor
 */
export function MenuContent({ activatedApp, applications, domains, settings, getToken }) {
    const [menuConfig, setMenuConfig] = useState(null);
    const i18next = usei18nextContext() || { t: () => {} };
    const routeClickHandler = useMenuItemProps() || { onClick: () => {} };

    useEffect(() => {
        const effect = async () => {
            const resp = await getMenuConfig({ host: settings.getEamxHost(), getToken }).catch((error) => {
                // eslint-disable-next-line no-console
                console.error('getMenuConfig error:', error);
            });

            // build list of available menu items
            const trayMenuConfig = transformMenuConfig(
                resp,
                i18next,
                applications,
                domains,
                activatedApp,
                settings.getHelpUrl(),
                routeClickHandler && routeClickHandler.onClick
            );

            setMenuConfig(trayMenuConfig);
        };

        effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!menuConfig) {
        return <div />;
    }

    return (
        <div data-testid="de-cmn-nxt-menu-content">
            <TrayMenu config={menuConfig} />
        </div>
    );
}

MenuContent.defaultProps = {
    activatedApp: 'homeapp',
    applications: [],
};

MenuContent.propTypes = {
    /** activated app **/
    activatedApp: PropTypes.string,
    /** array of applications **/
    applications: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string,
        })
    ),
    /** array of domains **/
    domains: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            subdomains: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    view: PropTypes.bool,
                })
            ),
        })
    ),
    /** setting object **/
    settings: PropTypes.shape({
        getEamxHost: PropTypes.func.isRequired,
        getHelpUrl: PropTypes.func.isRequired,
    }).isRequired,
    /** gets the auth token **/
    getToken: PropTypes.func.isRequired,
    /** url for help link **/
    helpUrl: PropTypes.string,
};

export default MenuContent;
