/**
 * @file Custom Redux Logger that utilizes the ABB webcore logger
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */

import { createLogger } from 'redux-logger';

/**
 * Create a custom Redux logger that utilizes the given ABB webcore logger
 * @param {object} logger - ABB webcore logger
 * @param {object} stateSanitizer - ABB redux sanitized state - Takes in state from app calling the custom logger method
 * @param {object} actionSanitizer - ABB redux sanitized action - Takes in actions from apps calling the custom logger method
 * @param {function} [predicate] - Optional function you can use to filter log messages, this is the redux logger predicate, see https://github.com/LogRocket/redux-logger#options
 * @returns {object} custom Redux logger
 */
export function createCustomReduxLogger (logger, stateSanitizer, actionSanitizer, predicate = undefined) {
    const customReduxLogger = createLogger({
        predicate: predicate,
        collapsed: true,
        timestamp: false,
        level: {
            prevState: 'logState',
            action: 'action',
            nextState: 'logState',
            error: 'error'
        },
        colors: {},
        stateTransformer: stateSanitizer,
        actionTransformer: actionSanitizer,
        titleFormatter: action => `action ${action.type}`,
        logger: {
            group: label => logger.group(label),
            groupEnd: () => logger.groupEnd(),
            groupCollapsed: label => logger.groupCollapsed(label),
            log: (msg) => logger.info(msg),
            error: (msg, error) => logger.error(msg, error),
            logState: (msg, state) => {
                try {
                    logger.info(`${msg} -> ${JSON.stringify(state)}`);
                } catch (ex) {
                    logger.error('Error serializing state', ex);
                }
            },
            action: (msg, action) => {
                logger.event(action.type);

                try {
                    logger.info(`${msg} -> ${JSON.stringify(action)}`);
                } catch (ex) {
                    logger.error('Error serializing action', ex);
                }
            }
        }
    });

    return customReduxLogger;
}

export default createCustomReduxLogger;