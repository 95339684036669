/**
 * @file Banner Component
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import React from 'react';
import { Stripe } from 'webcore-ux/nextgen/components/Stripe/Stripe';
import MenuContent from './MenuContent/MenuContent';
import RightContent from './RightContent/RightContent';
import PropTypes from 'prop-types';

/**
 * Renders the Banner component and it's contents
 * @param {string} appName - the user friendly app name
 * @param {string} activatedApp - the app to mark as activated in the menu
 * @param {object} settings - the application setting helper object
 * @param {function} getToken - the keycloak get token function
 * @param {object} currentUser - the current user object
 * @param {function} signOff - the sign off logic
 * @param {object} permissions - array of permission objects
 * @param {object} thirdPartyJson - the third party json object
 * @param {node} notificationsComponent - the notifications component
 * @param {node} headerOptionalActionsComponent - optional additional actions
 * @returns {JSX.Element|null} - The component to render
 * @constructor
 */
export function Banner({
    appName,
    activatedApp,
    settings,
    getToken,
    currentUser,
    signOff,
    permissions,
    thirdPartyJson,
    notificationsComponent,
    headerOptionalActionsComponent,
}) {
    /**
     * Redirects to landing when header is clicked
     */
    const handleHeaderClick = () => {
        window.location.href = settings.getLandingLink();
    };

    return (
        <Stripe
            menuContent={
                <MenuContent
                    activatedApp={activatedApp}
                    applications={permissions.applications}
                    domains={permissions.domains}
                    settings={settings}
                    getToken={getToken}
                />
            }
            rightContent={
                <>
                    {headerOptionalActionsComponent}
                    <RightContent
                        appName={appName}
                        settings={settings}
                        currentUser={currentUser}
                        signOff={signOff}
                        thirdPartyJson={thirdPartyJson}
                        notificationsComponent={notificationsComponent}
                    />
                </>
            }
            onClickBranding={handleHeaderClick}
        />
    );
}

Banner.defaultProps = {
    signOff: () => {},
    thirdPartyJson: {},
    currentUser: {},
    permissions: [],
};

Banner.propTypes = {
    /** the user friendly app name **/
    appName: PropTypes.string.isRequired,
    /** activated app **/
    activatedApp: PropTypes.string.isRequired,
    /** the application settings object **/
    settings: PropTypes.shape({
        getVersion: PropTypes.func.isRequired,
        getHelpUrl: PropTypes.func.isRequired,
        getLandingLink: PropTypes.func.isRequired,
        getEamxHost: PropTypes.func.isRequired,
    }).isRequired,
    /** getToken function **/
    getToken: PropTypes.func.isRequired,
    /** the current user object **/
    currentUser: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        initials: PropTypes.string,
    }),
    /** sign off function **/
    signOff: PropTypes.func,
    /** the applications permissions object **/
    permissions: PropTypes.shape({
        applications: PropTypes.arrayOf(PropTypes.shape({ code: PropTypes.string })),
        domains: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                subdomains: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        view: PropTypes.bool,
                    })
                ),
            })
        ),
    }),
    /** the third party json object **/
    thirdPartyJson: PropTypes.object,
    /** the notifications component **/
    notificationsComponent: PropTypes.node,
    /** optional additional header action icons **/
    headerOptionalActionsComponent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Banner;
