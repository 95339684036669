/**
 * @file Feature Flags module
 * @copyright © Copyright 2019 ABB. All rights reserved.
 * @module FeatureFlags
 */

import { send } from "./Send";
import Logger from "abb-webcore-logger/Logger";


let allowedFeatures = {};
let loaded = false;

/**
 * Check whether a feature is enabled
 * @param {string} flag feature flag that we are checking that is enabled
 * @returns {boolean} true if the feature is enabled.  false otherwise.
 */
export function enabled(flag) {

    //Convert to boolean
    return !!allowedFeatures[flag];
}

/**
 * Checks whether feature flags have been loaded
 * @returns {boolean} true if loaded, false otherwise
 */
export function getLoaded() {
    return loaded;
}


/**
 * Loads the feature flags from the server
 * @param {string} clientContext pass on relative context path specific to calling module
 * @param {string} [urlPrefix] prefix for the url to load
 * @param {function} [sender] Send function that returns a Promise - defaults to 'send' if not provided
 * @returns {Promise} Promise that resolves after the feature flags are loaded
 */
export function load(clientContext, urlPrefix = "", sender = send) {
    if (clientContext) {

        let url = `${clientContext}/v1/featureflags`;
        let sendPromise;

        if ((typeof urlPrefix === "string") && urlPrefix.length > 0) {
            url = `${urlPrefix}${url}`;
        }

        sendPromise = sender("GET", url, undefined);

        let loadPromise = new Promise(resolve => {
            sendPromise
                .then((resp) => {
                    resolve(JSON.parse(resp));
                })
                .catch(ex => {
                    let stringFlags = localStorage.getItem("allowedFeatures");
                    let flags = [];

                    try {
                        if (stringFlags) {
                            flags = JSON.parse(stringFlags);
                            flags = Object.getOwnPropertyNames(flags);
                        }
                    } catch (parseEx) {
                        localStorage.removeItem("allowedFeatures");
                        flags = [];
                    }

                    Logger.error(`Using cached feature flags: ${JSON.stringify(flags)}, error retrieving flags ${ex}`);
                    resolve({ features: flags });
                });
        });

        return loadPromise
            .then((features) => {
                loadFeatures(features);
                loaded = true;
                Logger.info(`Feature flags loaded: ${features.features}`);
            })
            .catch(ex => {
                //This shouldn't happen
                throw new Error(`Unexpected error processing feature flags: ${ex.toString()}`);
            });
         
    } else {
        throw new Error('clientContext paremater undefined or null');
    }  
}


/**
 * Load features into the class. Typically called from the load function.
 * @param {object} objFeat - The feature object returned from the web service
 * @param {string[]} objFeat.features - Array of features
 */
export function loadFeatures(objFeat) {
    // Iterate through objFeat
    objFeat.features.forEach(feature => {
        allowedFeatures[feature] = true;
    });

    // Don't cache development feature flags
    if (!allowedFeatures['DEV_FEATURE_FLAGS']) {
        localStorage.setItem("allowedFeatures", JSON.stringify(allowedFeatures));
    }
}

/**
 * Resets feature flags, etc.
 * @param {boolean} [clearStorage] flag indicating whether to also clear cached feature flags.  Default: false
 */
export function reset(clearStorage = false) {
    allowedFeatures = {};
    loaded = false;

    if (clearStorage) {
        localStorage.removeItem("allowedFeatures");
    }
}


export default {
    enabled,
    getLoaded,
    load,
    loadFeatures,
    reset
};