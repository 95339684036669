/**
 * @file useApiNotifications hook
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import { useCallback, useState } from 'react';
import Logger from 'abb-webcore-logger/Logger';
import { send, RESPONSE_TYPE } from 'webcore-common/Send';
import { NOTIFICATIONS } from '../../lib/constants';

/**
 * Hook used to fetch notifications for the logged in user
 * @returns {object} - list of Notifications and doFetchNotifications function
 */
const useApiNotifications = () => {
    const [notifications, setNotifications] = useState([]);

    /**
     * Hook used to fetch notifications for the logged in user
     * Filtering is done on end-result by entityUUID matching "notificationView"
     * @param {function} getToken - Function used for authentication
     * @param {string} host - Host name to send the fetch requests to
     * @param {string} initiator - Email of the user whose notifications are to be fetched
     * @param {number} offset - Offset to skip n number of notifications from the start
     * @param {number} since - Number of notifications to fetch in one request
     * @param {number} size - Number of notifications to fetch in one request
     * @returns {Promise} - a list of Notifications
     */
    const doFetchNotifications = useCallback(({ getToken, host, initiator, offset, since, size } = {}) => {
        if (!host || !initiator || !getToken || typeof getToken !== 'function') {
            const error = 'host, getToken and initiator are required';
            Logger.error(error);

            return Promise.reject(error);
        }

        const URL = `${host}/v1/notifications?since=${since}&size=${size}&offset=${offset}&initiator=${initiator}`;

        return send('GET', URL, undefined, getToken, undefined, RESPONSE_TYPE.JSON)
            .then((data) => {
                let ret = [];

                if (data && Array.isArray(data.notifications)) {
                    ret = data.notifications.filter((notification) => notification.entityUUID !== NOTIFICATIONS.PREFERENCES_ID);
                }

                setNotifications(ret);

                return ret;
            })
            .catch((err) => {
                Logger.error(`Failed to retrieve notifications: ${err}`);
            });
    }, []);

    return { notifications, doFetchNotifications, setNotifications };
};

export default useApiNotifications;
