/**
 * @file StorageService a lite session/local storage management solution
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
import { STORAGE_TYPES } from '../../lib/constants';

const storageTypeMap = {
    [STORAGE_TYPES.LOCAL]: localStorage,
    [STORAGE_TYPES.SESSION]: sessionStorage,
};

export default (type = STORAGE_TYPES.LOCAL) =>
    (key, value) => {
        const storageEngine = storageTypeMap[type];

        if (typeof value !== 'undefined') {
            storageEngine.setItem(key, JSON.stringify(value));
        } else {
            return JSON.parse(storageEngine.getItem(key));
        }
    };
