/**
 * @file widgetTransformConfig utility
 * @copyright © Copyright 2021 Hitachi ABB Power Grids. All rights reserved.
 */

import Logger from 'abb-webcore-logger/Logger';
import { widgetTypes, widgetChartTypes, widgetCountTypes } from '../../../constants';
import {
    getDataGridData,
    getAttachmentsData,
    getAttributesData,
    getWDSQueryData,
    getCqApiQueryData,
} from '../../../../services/get-widget-data/getWidgetData';

/**
 * open a passed summary kpi link in a new browser tab
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {string} config.kpi - the kpi to be queried
 * @returns {void} - no return
 */
export const openKpiLink = (config) => {
    const { domain, subdomain, kpi } = config;
    window.open(`/summary/#${domain}-${subdomain}--${kpi.replace(/\s/g, '')}`);
};

/**
 * open a passed summary kpi link in a new browser tab
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {Object[]} config.queries - the queries array
 * @param {string} config.queries.field - the field name to be queried
 * @param {string[]} config.queries.values - the field values to be queried
 * @returns {void} - no return
 */
export const openCountLink = (config) => {
    const { domain, subdomain, queries } = config;

    const query = {};

    queries.forEach((item) => {
        query[item.field] = item.values;
    });

    const encodedQuery = encodeURIComponent(JSON.stringify(query));
    window.open(`/summary/#${domain}-${subdomain}?filters=${encodedQuery}`);
};

/**
 * open a passed summary link in a new browser tab, containing a filtered query based on the cqQuery
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {Object[]} config.cqQuery - the cqQuery array
 * @returns {void} - no return
 */
export const openCountLinkWithCqApiQuery = (config) => {
    const { domain, subdomain, cqQuery } = config;

    let listViewFilter = {};
    cqQuery.forEach((query) => {
        if (query.filters) {
            listViewFilter = { ...listViewFilter, ...query.filters };
        }

        if (query.must) {
            listViewFilter = { ...listViewFilter, ...query.must };
        }
    });

    openListViewFilterLink({ domain, subdomain, listViewFilter });
};

/**
 * open a passed summary filters query link in a new browser tab
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {string} config.field - the field to be queried
 * @param {mouseEvent} config.mouseEvent - the click mouse event
 * @param {object} config.mouseEvent.point - the click mouse event point target
 * @param {string} config.mouseEvent.point.name - the click mouse event point target name
 * @returns {void} - no return
 */
export const openPieChartLink = (config) => {
    const { domain, subdomain, field, mouseEvent } = config;
    const { point } = mouseEvent;
    const query = {
        [field]: [point.name],
    };

    const encodedQuery = encodeURIComponent(JSON.stringify(query));
    const url = `/summary/#${domain}-${subdomain}?filters=${encodedQuery}`;

    window.open(url);
};

/**
 * open a passed summary filters query link in a new browser tab
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {string} config.field - the field to be queried
 * @param {mouseEvent} config.mouseEvent - the click mouse event
 * @param {object} config.mouseEvent.point - the click mouse event point target
 * @param {string} config.mouseEvent.point.category - the click mouse event point target category
 * @returns {void} - no return
 */
export const openBarChartLink = (config) => {
    const { domain, subdomain, field, mouseEvent } = config;
    const { point } = mouseEvent;
    const query = {
        [field]: [point.category],
    };

    const encodedQuery = encodeURIComponent(JSON.stringify(query));
    const url = `/summary/#${domain}-${subdomain}?filters=${encodedQuery}`;

    window.open(url);
};

/**
 * open a passed summary filters query link in a new browser tab
 * @param {object} config - the service config object
 * @param {string} config.domain - the domain to be queried
 * @param {string} config.subdomain - the subdomain to be queried
 * @param {string} config.listViewFilter - the filter to be used
 * @returns {void} - no return
 */
export const openListViewFilterLink = (config) => {
    const { domain, subdomain, listViewFilter } = config;
    const encodedQuery = encodeURIComponent(JSON.stringify(listViewFilter));
    const url = `/summary/#${domain}-${subdomain}?filters=${encodedQuery}`;

    window.open(url);
};

/**
 * selects a getData function based on passed config
 * @param {function} getToken - the get token function from auth
 * @param {string} host - the host url
 * @param {object} card - the card object config
 * @param {number} card.type - the type of card
 * @param {number} card.subtype - the sub type of card
 * @param {object} card.params - the card parameters
 * @param {string} card.params.domain - the domain data source
 * @param {string} card.params.subDomain - the sub domain data source
 * @param {object} card.params.chartPie.wdsQuery - the WDS query object
 * @param {string} card.params.chartPie.wdsActionPath - the WDS action path
 * @param {string} card.params.chartPie.overrides.getDataFunctionName - the optional getData override function name
 * @param {object} card.params.chartBar.wdsQuery - the WDS query object
 * @param {string} card.params.chartBar.wdsActionPath - the WDS action path
 * @param {string} card.params.chartBar.overrides.getDataFunctionName - the optional getData override function name
 * @param {object} card.params.kpi.wdsQuery - the WDS query object
 * @param {string} card.params.kpi.wdsIndex - a query index to be attached to the getData url
 * @param {string} card.params.kpi.wdsActionPath - the WDS action path
 * @param {string} card.params.kpi.overrides.getDataFunctionName - the optional getData override function name
 * @param {object} card.params.count.wdsQuery - the WDS query object
 * @param {string} card.params.count.wdsActionPath - the WDS action path
 * @param {object} card.params.count.cqQuery - the cq-api query object
 * @param {object} card.params.count.cqURL - the cq-api url to be used
 * @param {string} card.params.count.cqResponsePath - the path to the attribute in the cq-api response that will contain the data used in the widget
 * @param {string} card.params.count.overrides.getDataFunctionName - the optional getData override function name
 * @param {object} card.params.dataGrid.payload - the optional getData payload parameter
 * @param {string} card.params.dataGrid.overrides.getDataFunctionName - the optional getData override function name
 * @param {string} card.params.attachments.overrides.getDataFunctionName - the optional getData override function name
 * @param {string} card.params.attributes.overrides.getDataFunctionName - the optional getData override function name
 * @param {object} overrides - the optional overrides object
 * @returns {function} - the selected getData function
 */

export const getWidgetDataSelector = (getToken, host, card, overrides) => {
    const { domain, subDomain, chartPie, chartBar, kpi, count, attachments, attributes, dataGrid } = card.params;

    switch (card.type) {
        case widgetTypes.chart:
            switch (card.subtype) {
                case widgetChartTypes.pie:
                    return chartPie.overrides && chartPie.overrides.getDataFunctionName
                        ? overrides.chartPie[chartPie.overrides.getDataFunctionName]
                        : getWDSQueryData({
                              domain: domain,
                              subdomain: subDomain,
                              wdsQuery: chartPie.wdsQuery,
                              wdsIndex: chartPie.wdsIndex,
                              wdsActionPath: chartPie.wdsActionPath,
                              token: getToken,
                              host: host,
                          });

                case widgetChartTypes.bar:
                    return chartBar.overrides && chartBar.overrides.getDataFunctionName
                        ? overrides.chartBar[chartBar.overrides.getDataFunctionName]
                        : getWDSQueryData({
                              domain: domain,
                              subdomain: subDomain,
                              wdsQuery: chartBar.wdsQuery,
                              wdsIndex: chartBar.wdsIndex,
                              wdsActionPath: chartBar.wdsActionPath,
                              token: getToken,
                              host: host,
                          });

                default:
                    Logger.debug('unsupported card chart config subtype: ' + card.subtype);
            }

            break;

        case widgetTypes.kpi:
            return kpi.overrides && kpi.overrides.getDataFunctionName
                ? overrides.kpi[kpi.overrides.getDataFunctionName]
                : getWDSQueryData({
                      domain: domain,
                      subdomain: subDomain,
                      wdsQuery: kpi.wdsQuery,
                      wdsIndex: kpi.wdsIndex,
                      wdsActionPath: kpi.wdsActionPath,
                      token: getToken,
                      host: host,
                  });

        case widgetTypes.count:
            switch (card.subtype) {
                case widgetCountTypes.wds:
                    return count.overrides && count.overrides.getDataFunctionName
                        ? overrides.count[count.overrides.getDataFunctionName]
                        : getWDSQueryData({
                              domain: domain,
                              subdomain: subDomain,
                              wdsQuery: count.wdsQuery,
                              wdsActionPath: count.wdsActionPath,
                              token: getToken,
                              host: host,
                          });

                case widgetCountTypes.cq:
                    return getCqApiQueryData({
                        cqURL: count.cqURL,
                        cqQuery: count.cqQuery,
                        cqResponsePath: count.cqResponsePath,
                        token: getToken,
                        host: host,
                    });

                default:
                    Logger.debug('unsupported count chart config subtype: ' + card.subtype);
            }

            break;

        case widgetTypes.dataGrid:
            return dataGrid.overrides && dataGrid.overrides.getDataFunctionName
                ? overrides.dataGrid[dataGrid.overrides.getDataFunctionName]
                : getDataGridData({
                      domain: domain,
                      subdomain: subDomain,
                      token: getToken,
                      host: host,
                      payload: dataGrid.payload,
                  });

        case widgetTypes.attachments:
            return attachments.overrides && attachments.overrides.getDataFunctionName
                ? overrides.attachments[attachments.overrides.getDataFunctionName]
                : getAttachmentsData({
                      token: getToken,
                      host: host,
                      attachmentUUIDs: attachments.attachmentUUIDs,
                  });

        case widgetTypes.attributes:
            return attributes.overrides && attributes.overrides.getDataFunctionName
                ? overrides.attributes[attributes.overrides.getDataFunctionName]
                : getAttributesData({
                      domain: domain,
                      subdomain: subDomain,
                      token: getToken,
                      host: host,
                      viewSettings: attributes.viewSettings,
                  });
        default:
            Logger.debug('unsupported card config type: ' + card.type);
    }
};

/**
 * gets an onclick event handler based on passed config
 * @param {object} card - the card object config
 * @param {number} card.type - the type of card
 * @param {number} card.subtype - the sub type of card
 * @param {object} card.params - the card parameters
 * @param {string} card.params.domain - the domain data source
 * @param {string} card.params.subDomain - the sub domain data source
 * @param {string} card.params.chartPie.field - the field to be referenced
 * @param {string} card.params.chartBar.field - the field to be referenced
 * @param {object} card.params.chartPie.wdsQuery - the WDS query object
 * @param {string} card.params.chartPie.wdsActionPath - the WDS action path
 * @param {string} card.params.chartPie.overrides.onClickFunctionName - the optional onClick override function name
 * @param {object} card.params.chartBar.wdsQuery - the WDS query object
 * @param {string} card.params.chartBar.wdsActionPath - the WDS action path
 * @param {string} card.params.chartBar.overrides.onClickFunctionName - the optional onClick override function name
 * @param {object} card.params.kpi.wdsQuery - the WDS query object
 * @param {string} card.params.kpi.wdsActionPath - the WDS action path
 * @param {string} card.params.kpi.listViewFilter - the filter string for list view link
 * @param {string} card.params.kpi.kpiValue - the kpi value used for opening links in ListView
 * @param {string} card.params.kpi.overrides.onClickFunctionName - the optional onClick override function name
 * @param {object} card.params.count.wdsQuery - the WDS query object
 * @param {string} card.params.count.wdsActionPath - the WDS action path
 * @param {string} card.params.count.listViewFilter - the filter string for list view link
 * @param {string} card.params.count.overrides.onClickFunctionName - the optional onClick override function name
 * @param {object} overrides - the optional overrides object
 * @returns {function} - the onclick handler function
 */
export const getWidgetOnClickSelector = (card, overrides = {}) => {
    const { domain, subDomain, chartPie, chartBar, kpi, count } = card.params;

    switch (card.type) {
        case widgetTypes.chart:
            switch (card.subtype) {
                case widgetChartTypes.pie:
                    return chartPie.overrides && chartPie.overrides.onClickFunctionName
                        ? (mouseEvent) => overrides.chartPie[chartPie.overrides.onClickFunctionName](mouseEvent)
                        : (mouseEvent) =>
                              openPieChartLink({
                                  domain,
                                  subdomain: subDomain,
                                  field: chartPie.wdsQuery.aggregation.term.field,
                                  mouseEvent,
                              });
                case widgetChartTypes.bar:
                    return chartBar.overrides && chartBar.overrides.onClickFunctionName
                        ? (mouseEvent) => overrides.chartBar[chartBar.overrides.onClickFunctionName](mouseEvent)
                        : (mouseEvent) =>
                              openBarChartLink({
                                  domain,
                                  subdomain: subDomain,
                                  field: chartBar.wdsQuery.aggregation.term.field,
                                  mouseEvent,
                              });
                default:
                    Logger.debug('unsupported card chart config subtype: ' + card.subtype);
            }

            break;

        case widgetTypes.kpi:
            return kpi.overrides && kpi.overrides.onClickFunctionName
                ? () => overrides.kpi[kpi.overrides.onClickFunctionName]()
                : () => openKpiLink({ domain, subdomain: subDomain, kpi: kpi.kpiValue });
        case widgetTypes.count:
            switch (card.subtype) {
                case widgetCountTypes.wds:
                    return count.overrides && count.overrides.onClickFunctionName
                        ? () => overrides.count[count.overrides.onClickFunctionName]()
                        : () =>
                              openListViewFilterLink({
                                  domain: domain,
                                  subdomain: subDomain,
                                  listViewFilter: count.listViewFilter,
                              });

                case widgetCountTypes.cq:
                    return () => {
                        return openCountLinkWithCqApiQuery({
                            domain: domain,
                            subdomain: subDomain,
                            cqQuery: count.cqQuery,
                        });
                    };

                default:
                    Logger.debug('unsupported count chart config subtype: ' + card.subtype);
            }

            break;

        default:
            Logger.debug('unsupported card config type: ' + card.type);
    }
};

export default {
    getWidgetDataSelector,
    getWidgetOnClickSelector,
};
