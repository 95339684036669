import Highcharts from 'highcharts';

export const redrawHighchartsCharts = (defaultTimeout = 200) => {
    const updateCharts = () => {
        if (Highcharts.charts.length) {
            Highcharts.charts.forEach((chart) => {
                if (chart) {
                    chart.reflow();
                }
            });
        }
    };

    setTimeout(updateCharts, defaultTimeout);
};
